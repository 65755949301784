export default {
  // root
  rate_settings:             require('./rate_settings.js').default,
  user_management:           require('./user_management.js').default,

  // Data
  users:                     require('./users.js').default,
  appointments:              require('./appointments.js').default,
  promocodes:                require('./promocodes.js').default,
  corporate_sites:           require('./corporate_sites.js').default,
  webinars:                  require('./webinars.js').default,
  webinar_bookings:          require('./webinar_bookings.js').default,
  reports:                   { section: 'Data', config: { title: 'Reports' } },
  surveys:                   require('./surveys.js').default,
  banners:                   require('./banners.js').default,

  // Booking settings
  therapist_scores:          require('./therapist_scores.js').default,
  countries:                 require('./countries.js').default,
  languages:                 require('./languages.js').default,

  // Logs
  audit_logs:                require('./audit_logs.js').default,

  settings:                  require('./settings.js').default,
}
