import React from 'react';
import classnames from 'classnames';

import '../../sass/components/common/TabsView.scss';

class TabsView extends React.Component {
  constructor(props) {
    super();
    this.state = {
    }
  }

  render = () => {
    return (
      <div
        className={classnames({
          'tabsView': true,
          'compact': this.props.compact,
        })}
      >
        <div className='tabsContainer'>
          <div className='tabsList'>
            {this.props.tabs.map(tab =>
              <div
                key={tab.key}
                className={classnames({
                  'tabItem': true,
                  'active': this.props.tab === tab.key,
                })}
                onClick={() => this.props.onTabChange(tab.key)}
              >
                {tab.icon ?
                  <span className='material-icons'>{tab.icon}</span>
                : null}
                {tab.title}
              </div>
            )}
          </div>
        </div>
        <div className='tabsBorder'/>
      </div>
    )
  }

}

export default TabsView;
