export default {
  config: {
    type: "settings",
    method: "me/settings/",
    title: "Profile",
  },
  properties: {
    email: {
      default: "",
      type: "text",
      title: "Email",
      modifyCondition: object => object.admin_write,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      modifyCondition: object => object.admin_write,
    },
    password: {
      default: "",
      type: "password",
      title: "Password",
    },
  }
};
