import React from 'react';

import SelectInput from './input/SelectInput.js';
import CheckboxInput from './input/CheckboxInput.js';
import SwitchInput from './input/SwitchInput.js';
import DateInput from './input/DateInput.js';
import WysiwygInput from './input/WysiwygInput.js';
import TextInput from './input/TextInput.js';
import JsonInput from './input/JsonInput.js';
import FileInput from './input/FileInput.js';
import LinkInput from './input/LinkInput.js';
import PageLink from './input/PageLink.js';
import ImageInput from './input/ImageInput.js';
import LogsList from './input/LogsList.js';
import TodoList from './input/TodoList.js';
import LinksList from './input/LinksList.js';
import UserChats from './input/UserChats.js';
import ChangesInput from './input/ChangesInput';
import DocumentsList from './input/DocumentsList';
import ChecklistInput from './input/ChecklistInput';
import SendCalendlyButton from './common/SendCalendlyButton';
import InvoicesView from './input/InvoicesView';
import TypeformDataView from './input/TypeformDataView';
import ChecklistsGroupInput from './input/ChecklistsGroupInput';
import AppointmentUserInfo from './input/AppointmentUserInfo';
import BookingsInfo from './input/BookingsInfo';
import SessionSettingsInput from './input/SessionSettingsInput';
import MultiTextInput from './input/MultiTextInput';
import PhotosInput from './input/PhotosInput.js';
import SurveyQuestionsView from './input/SurveyQuestionsView.js';
import LabelsList from './input/LabelsList.js';
import ClientActivity from './input/ClientActivity.js';
import DurationInput from './input/DurationInput.js';

import UserAvatarView from './UserAvatarView';

export default class FlexibleInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    var properties = this.props.properties;
    switch (properties.type) {
      case "text":
      case "number":
      case "integer":
      case "password":
      case "textarea":
      case "amount":
        return <TextInput  properties={properties} {...this.props} />
      case "multi-text":
        return <MultiTextInput  properties={properties} {...this.props} />
      case "wysiwyg":
        return <WysiwygInput properties={properties} {...this.props} />
      case "select":
      case "multi-select":
        return <SelectInput properties={properties} {...this.props} />
      case "checkbox":
        return <CheckboxInput properties={properties} {...this.props} />
      case "switch":
        return <SwitchInput properties={properties} {...this.props} />
      case "date":
      case "datetime":
        return <DateInput properties={properties} {...this.props} />
      case "file":
        return <FileInput properties={properties} {...this.props} />
      case "link":
        return <LinkInput properties={properties} {...this.props} />
      case "json":
        return <JsonInput properties={properties} {...this.props} />
      case "avatar":
        return <UserAvatarView properties={properties} {...this.props} />
      case "page-link":
        return <PageLink properties={properties} {...this.props} />
      case "image":
        return <ImageInput properties={properties} {...this.props} />
      case "logs-list":
        return <LogsList properties={properties} {...this.props} />
      case "todo-list":
        return <TodoList properties={properties} {...this.props} />
      case "links-list":
        return <LinksList properties={properties} {...this.props} />
      case "user-chats":
        return <UserChats properties={properties} {...this.props} />
      case "changes":
        return <ChangesInput properties={properties} {...this.props} />
      case "documents-list":
        return <DocumentsList properties={properties} {...this.props} />
      case "checklist":
        return <ChecklistInput properties={properties} {...this.props} />
      case "send-calendly":
        return <SendCalendlyButton properties={properties} {...this.props} />
      case "invoices":
        return <InvoicesView properties={properties} {...this.props} />
      case "typeform-data":
        return <TypeformDataView properties={properties} {...this.props} />
      case "items-group":
        return <ChecklistsGroupInput properties={properties} {...this.props} />
      case "appointment-user-info":
        return <AppointmentUserInfo properties={properties} {...this.props} />
      case "bookings-info":
        return <BookingsInfo properties={properties} {...this.props} />
      case "session-settings":
        return <SessionSettingsInput properties={properties} {...this.props} />
      case "photos":
        return <PhotosInput properties={properties} {...this.props} />
      case "survey-questions":
        return <SurveyQuestionsView properties={properties} {...this.props} />
      case "labels-list":
        return <LabelsList properties={properties} {...this.props} />
      case "client-activity":
        return <ClientActivity properties={properties} {...this.props} />
      case "duration":
        return <DurationInput properties={properties} {...this.props} />
      default:
        return null;
    }
  }
}
