export default {
  section: "Booking Settings",
  config: {
    method: "countries/",
    title: "Countries",
    objectName: "Country",
    search: {
      name: {
        type: "text",
        title: "Name",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
      required: true,
    },
    active: {
      default: false,
      type: "checkbox",
      title: "Active",
      width: "auto",
    },
  }
};
