import React from 'react';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';
import Storage from '../helpers/Storage.js';

import FlexibleInput from './FlexibleInput';

import '../sass/components/SettingsView.scss';

class SettingsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props)
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    return {
      properties,
      config,
      object: {...Storage.getData('user')},
      errors: {},
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  handleSubmit = () => {
    let formData = new FormData();
    Object.keys(this.state.object).forEach(key => {
      const value = this.state.object[key];
      const properties = this.state.properties[key];
      if (!properties) {
      } else if (value === null || value === undefined) {
        if (properties.type === 'select') {
          formData.append(key, '');
        }
      } else if (['image', 'file'].indexOf(properties.type) > -1) {
        if (typeof value !== 'string') {
          formData.append(key, value);
        }
      } else if (properties.type === 'multi-select') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    sendRequest({
      method: this.state.config.method,
      type: 'POST',
      formData,
      success: (data) => {
        Storage.setData('user', data);
        triggerEvent('showSnackbar', [{text: 'Account updated', type: 'success'}]);
        this.setState({object: data});
      },
      error: (data) => {
        if (data.errors) {
          this.setState({errors: data.errors});
        }
      }
    });
  }

  handleValueChange = (key, value) => {
    this.setState({
      object: {
        ...this.state.object,
        [key]: value,
      },
      errors: {
        ...this.state.errors,
        [key]: null,
      },
    });
  }

  renderInput = (key) => {
    const inputProperties = this.state.properties[key];
    if (!inputProperties.type || ["none", "avatar"].indexOf(inputProperties.type) > -1) {
      return null;
    }
    if (inputProperties.showCondition && !inputProperties.showCondition(this.state.object)) {
      return null;
    }
    let groupHidden = inputProperties.preset && !this.state.object.id;
    let inputDisabled = inputProperties.preset;
    if (inputProperties.createOnly) {
      inputDisabled = true;
    }
    if (inputProperties.modifyCondition && !inputProperties.modifyCondition(this.state.object)) {
      inputDisabled = true;
    }
    if (this.state.config.readOnly) {
      inputDisabled = true;
    }
    return (
      <div
        key={key}
        className={classnames({
          'inputContainer': true,
          'hidden': groupHidden,
        })}
      >
        <label>{inputProperties.title}</label>
        <div className='inputElem'>
          <FlexibleInput
            object={this.state.object[key]}
            parentObject={this.state.object}
            onChange={this.handleValueChange}
            disabled={inputDisabled}
            objectKey={key}
            properties={inputProperties}
          />
          {this.state.errors[key] ?
            <div className='inputError'>{this.state.errors[key]}</div>
          : null}
        </div>
      </div>
    );
  }

  render = () => {
    return (
      <div className='settingsView'>

        <div className='card'>
          <div className='inputsBlock'>
            {Object.keys(this.state.properties).map(this.renderInput)}
          </div>
          <div className='buttonContainer'>
            <button onClick={this.handleSubmit}>Save</button>
          </div>
        </div>

      </div>
    )
  }
}

export default (withRouter(SettingsView));
