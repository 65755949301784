import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../../sass/components/input/BookingsInfo.scss';

const STATUSES = [
  { id: "pending", name: "Draft" },
  { id: "active", name: "Active" },
  { id: "completed", name: "Completed" },
];

const CURRENCIES = [
  { id: "sgd", name: "SGD" },
  { id: "myr", name: "MYR" },
  { id: "php", name: "PHP" },
  { id: "usd", name: "USD" },
];

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class BookingsInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderBookingLink = (item) => {
    if (!item?.id) {
      return <td/>
    }
    return (
      <td>
        <span
          onClick={() => this.props.history.push(`/webinar_bookings/${item.id}`)}
        >{item.id}</span>
      </td>
    )
  }

  renderUserLink = (item) => {
    if (!item?.id) {
      return <td/>
    }
    return (
      <td>
        <span
          onClick={() => this.props.history.push(`/users/${item.id}`)}
        >{item.name}</span>
      </td>
    )
  }

  renderDatetime = (content) => {
    return moment.unix(content).format('DD.MM.YYYY HH:mm')
  }

  renderStatusName = (status) => {
    const item = STATUSES.find(i => i.id === status);
    return item?.name || '';
  }

  renderCurrencyName = (currency) => {
    const item = CURRENCIES.find(i => i.id === currency);
    return item?.name || '';
  }

  renderInfoItem = (item) => {
    if (this.props.isMobile) {
      return (
        <table key={item.id} className='card'>
          <tbody>
            <tr>
              <td>#</td>
              {this.renderBookingLink(item)}
            </tr>
            <tr>
              <td>Corporate</td>
              {this.renderUserLink(item.corporate_object)}
            </tr>
            <tr>
              <td>Starts at</td>
              <td>{this.renderDatetime(item.starts_at)}</td>
            </tr>
            <tr>
              <td>Ends at</td>
              <td>{this.renderDatetime(item.ends_at)}</td>
            </tr>
            <tr>
              <td>Therapist</td>
              {this.renderUserLink(item.therapist_object)}
            </tr>
            <tr>
              <td>Price</td>
              <td>{item.price}</td>
            </tr>
            <tr>
              <td>Counsellor price</td>
              <td>{item.counsellor_price}</td>
            </tr>
            <tr>
              <td>Currency</td>
              <td>{this.renderCurrencyName(item.currency)}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{this.renderStatusName(item.status)}</td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return (
        <tr key={item.id}>
          {this.renderBookingLink(item)}
          {this.renderUserLink(item.corporate_object)}
          <td>{this.renderDatetime(item.starts_at)}</td>
          <td>{this.renderDatetime(item.ends_at)}</td>
          {this.renderUserLink(item.therapist_object)}
          <td>{item.price}</td>
          <td>{item.counsellor_price}</td>
          <td>{this.renderCurrencyName(item.currency)}</td>
          <td>{this.renderStatusName(item.status)}</td>
        </tr>
      )
    }
  }

  render = () => {
    const items = this.props.object || [];
    return (
      <div className='bookingsInfo'>
        {this.props.isMobile
          ? items.map(this.renderInfoItem)
          : <table>
              <thead>
                <tr>
                  <th width="100px">#</th>
                  <th>Corporate</th>
                  <th>Starts at</th>
                  <th>Ends at</th>
                  <th>Counsellor</th>
                  <th width="100px">Price</th>
                  <th width="100px">Counsellor price</th>
                  <th width="100px">Currency</th>
                  <th>Status</th>
                </tr>
              </thead>
            <tbody>
              {items.map(this.renderInfoItem)}
            </tbody>
          </table>
        }
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(BookingsInfo));
