export default {
  rootSection: true,
  config: {
    method: "admin_users/",
    title: "User Management",
    objectName: "Admin User",
    disableDelete: true,
    search: {
      name: {
        type: "text",
        title: "Name",
      },
      email: {
        type: "text",
        title: "Email",
      }
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto",
    },
    password: {
      type: "password",
      title: "Password",
    },
    admin_write: {
      type: "checkbox",
      title: "Write",
      width: "80px",
    },
    active: {
      type: "checkbox",
      title: "Active",
      width: "80px",
    },
  }
};
