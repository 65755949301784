export default {
  section: "Data",
  config: {
    method: "promocodes/",
    title: "Promocodes",
    objectName: "Promocode",
    bulkEditMethod: "promocodes/bulk",
    bulkActions: true,
    editView: require('../components/editControls/EditViewWithLogs.js').default,
    search: {
      code: {
        type: "text",
        title: "Code",
      },
      status: {
        type: "select",
        title: "Status",
        options: [
          { id: "pending", name: "Pending" },
          { id: "active", name: "Active" },
          { id: "expired", name: "Expired" },
          { id: "used", name: "Used" },
        ],
      },
      patient_id: {
        type: "select",
        title: "Patient",
        async: true,
        clearable: true,
        request: "users/search?role=patient",
      },
      therapist_id: {
        type: "select",
        title: "Therapist",
        clearable: true,
        async: true,
        request: "users/search?role=therapist",
      },
      corporate_id: {
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      expire_at_min: {
        type: "datetime",
        title: "Expire from (SGT)",
      },
      expire_at_max: {
        type: "datetime",
        title: "Expire to (SGT)",
      },
    },
    bulk: {
      patient_ids: {
        default: null,
        type: "multi-select",
        title: "Patients",
        async: true,
        clearable: true,
        request: "users/search?role=patient",
        requestAll: "promocodes/:id/patients",
        multipleSearch: true,
        searchKeys: [
          { id: 'name', name: 'Name' },
          { id: 'email', name: 'Email' },
        ],
      },
      therapist_ids: {
        default: null,
        type: "multi-select",
        title: "Therapists",
        clearable: true,
        async: true,
        request: "users/search?role=therapist",
        requestAll: "promocodes/:id/therapists",
        multipleSearch: true,
        searchKeys: [
          { id: 'name', name: 'Name' },
          { id: 'email', name: 'Email' },
        ],
      },
      corporate_id: {
        default: null,
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      promocode_type: {
        default: null,
        type: "select",
        title: "Promocode type",
        options: [
          { id: "admin", name: "Safe Space issued" },
          { id: "gift", name: "Gift" },
          { id: "therapist_initiated", name: "Therapist-initiated" },
          { id: "corporate_clients", name: "Corporate clients" },
          { id: "partners", name: "Partners" },
          { id: "sponsored", name: "Sponsored" },
          { id: "refund", name: "Safe Space client refunds/reschedules" },
          { id: "bulk_discount", name: "Bulk Discount" },
          { id: "internal", name: "Safe Space Internal" },
        ],
      },
      counselling_type: {
        default: null,
        type: "select",
        title: "Counselling type",
        options: [
          { id: 'video_call', name: 'Video call' },
          { id: 'face_to_face', name: 'Face to face' },
        ],
      },
      status: {
        default: null,
        type: "select",
        title: "Status",
        options: [
          { id: "pending", name: "Pending" },
          { id: "active", name: "Active" },
          { id: "expired", name: "Expired" },
          { id: "used", name: "Used" },
        ],
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created at",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified at",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
    },
    code: {
      default: "",
      type: "text",
      title: "Code",
      width: "auto",
    },
    patient_ids: {
      default: null,
      type: "multi-select",
      title: "Patients",
      async: true,
      clearable: true,
      request: "users/search?role=patient",
      requestAll: "promocodes/:id/patients",
      extraOptionKeys: ['email'],
    },
    for_new_user: {
      type: "checkbox",
      default: false,
      title: "Applicable to new user only",
    },
    pending_appointments_limit: {
      type: "checkbox",
      default: false,
      title: "Pending appointments limit",
    },
    therapist_ids: {
      default: null,
      type: "multi-select",
      title: "Therapists",
      clearable: true,
      async: true,
      request: "users/search?role=therapist",
      requestAll: "promocodes/:id/therapists",
    },
    corporate_id: {
      default: null,
      type: "select",
      title: "Corporate",
      clearable: true,
      async: true,
      request: "users/search?role=corporate",
    },
    promocode_type: {
      type: "select",
      title: "Promocode type",
      options: [
        { id: "admin", name: "Safe Space issued" },
        { id: "gift", name: "Gift" },
        { id: "therapist_initiated", name: "Therapist-initiated" },
        { id: "corporate_clients", name: "Corporate clients" },
        { id: "partners", name: "Partners" },
        { id: "sponsored", name: "Sponsored" },
        { id: "refund", name: "Safe Space client refunds/reschedules" },
        { id: "bulk_discount", name: "Bulk Discount" },
        { id: "internal", name: "Safe Space Internal" },
      ],
      required: true,
      width: "auto",
    },
    counselling_type: {
      type: "select",
      title: "Counselling type",
      options: [
        { id: 'video_call', name: 'Video call' },
        { id: 'face_to_face', name: 'Face to face' },
      ],
    },
    session_type: {
      type: "select",
      title: "Session type",
      options: [
        { id: 'individual', name: 'Individual' },
        { id: 'family', name: 'Family' },
        { id: 'couple', name: 'Couple' },
      ],
    },
    maximum_usage: {
      default: "",
      type: "text",
      title: "Maximum usage",
      width: "140px",
    },
    expire_at_unix: {
      default: "",
      type: "datetime",
      title: "Expire at (SGT)",
      width: "140px",
      required: true,
    },
    status: {
      type: "select",
      title: "Status",
      width: "100px",
      default: "pending",
      options: [
        { id: "pending", name: "Pending" },
        { id: "active", name: "Active" },
        { id: "expired", name: "Expired" },
        { id: "used", name: "Used" },
      ],
    },
    deduction_type: {
      type: "select",
      title: "Deduction type",
      default: "promocode_value",
      options: [
        { id: "promocode_value", name: "Promocode value" },
        { id: "appt_price", name: "Appointment price" },
        { id: "appt_price_capped", name: "Appointment price capped" },
      ],
      modifyCondition: object => !object.id,
    },
    unit: {
      type: "select",
      title: "Unit",
      width: "100px",
      options: [
        { id: "percent", name: "%" },
        { id: "fixed_amount", name: "Fixed S$" },
      ],
      required: true,
      showCondition: object => object.deduction_type === "promocode_value",
    },
    unit_percent: {
      default: "%",
      type: "text",
      title: "Unit",
      modifyCondition: () => false,
      showCondition: object => object.deduction_type === "appt_price",
    },
    unit_fixed: {
      default: "Fixed S$",
      type: "text",
      title: "Unit",
      modifyCondition: () => false,
      showCondition: object => object.deduction_type === "appt_price_capped",
    },
    amount: {
      default: "",
      type: "text",
      title: "Amount",
      width: "100px",
      required: true,
    },
    purchase_value: {
      default: "",
      type: "text",
      title: "Purchase value",
      showCondition: object => object.promocode_type === 'bulk_discount',
      required: true,
    },
    max_usage_per_patient: {
      default: "",
      type: "text",
      title: "Max usage per client",
    },
    description: {
      default: "",
      type: "textarea",
      title: "Description",
      width: "auto",
      required: true,
    },
    total_value: {
      type: "number",
      title: "Total Value",
    },
    total_used_discount: {
      type: "text",
      title: "Usage amount",
      modifyCondition: object => false,
    },
  }
};
