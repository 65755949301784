export default {
  section: "Booking Settings",
  config: {
    method: "therapist_scores/",
    title: "Therapist scores",
    objectName: "Therapist score",
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    therapist_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Therapist",
      width: "auto",
      showCondition: () => false,
    },
    therapist_id: {
      type: "select",
      title: "Therapist",
      clearable: true,
      async: true,
      request: "users/search?role=therapist",
    },
    score: {
      default: "",
      type: "text",
      title: "Score",
      width: "auto",
    },
  }
};
