import React from 'react';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';
import classnames from 'classnames';

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import SearchView from './SearchView';
import ObjectListElem from './ObjectListElem';
import Pagination from './common/Pagination';
import FileInput from './input/FileInput';
import CheckboxInput from './input/CheckboxInput';

import '../sass/components/ObjectListView.scss';

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
    user: store.data.user,
  }
};

class ObjectListView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  getDefaultState = (props) => {
    const properties = appConfig[props.configKey].properties;
    const config = appConfig[props.configKey].config;
    let search = {};
    // Default search interval
    if (config.search) {
      Object.keys(config.search).forEach((key) => {
        search[key] = config.search[key].default || null;
      });
    }
    this.props.history.location.search.replace(/^\?/, '').split('&').forEach(p => {
      const pair = p.split('=');
      if (pair[0]) {
        search[pair[0]] = pair[1] || true;
      }
    });
    const page = (Number(this.props.location.hash.replace(/\D/g, '')) || 1) - 1;
    return {
      objects: [],
      properties,
      config,
      options: {},
      search,
      sort: 'id',
      sort_order: 'desc',
      page,
      maxPage: 0,
      bulkSelect: false,
      selectedItems: [],
      selectedAll: false,
      objectCount: 0,
      objectIds: [],
      missedObjects: null
    };
  }

  componentDidMount = () => {
    if (!this.state.config.search) {
      this.requestData();
    }
    this.requestOptions();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey !== prevProps.configKey) {
      this.setState(this.getDefaultState(this.props), () => {
        this.requestData();
        this.requestOptions();
      })
    }
  }

  requestOptions = () => {
    let options = Object.assign({}, this.state.options);
    Object.keys(this.state.properties).forEach(key => {
      if (this.state.properties[key].width && this.state.properties[key].type === "select") {
        if (this.state.properties[key].request) {
          sendRequest({
            type: "GET",
            method: this.state.properties[key].request,
            success: (data) => {
              this.setState({options:
                update(this.state.options, {
                  [key]: {$set: data}
                })
              });
            },
            error: (data) => {}
          });
        } else if (this.state.properties[key].options) {
          options[key] = this.state.properties[key].options;
        }
      }
    });
    this.setState({options});
  }

  requestData = (callback = null) => {
    const search = this.state.search;
    const searchParams = Object.keys(search)
      .filter(key => key && search[key] !== null && search[key] !== undefined && search[key] !== '')
      .map(key => `${key}=${search[key]}`)
      .join('&');
    this.props.history.replace(`${this.props.location.pathname}?${searchParams}#${this.state.page + 1}`);
    sendRequest({
      method: this.state.config.method,
      data: {
        offset: this.state.page * ITEMS_PER_PAGE,
        sort: this.state.sort,
        sort_order: this.state.sort_order,
        bulk_edit: this.state.selectedAll,
        ...search,
      },
      type: "GET",
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
        this.setState({
          objects: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
          objectCount: data.count,
          objectIds: data.bulk_ids,
          missedObjects: data.missed_objects
        }, () => {
          if (callback) {
            callback();
          }
        });
      },
      error: (data) => {
      }
    });
  }

  onBulk = () => {
    const config = this.state.config.bulk || {};
    triggerEvent('showBulkEditPopup', [{
      title: `Bulk Edit for the ${this.state.selectedItems.length} items selected`,
      config: config,
      items: this.state.selectedItems,
      entity: this.state.config.title,
      callback: data => {
        data['ids'] = this.state.selectedItems;
        sendRequest({
          method: this.state.config.bulkEditMethod,
          data: data,
          type: 'PUT',
          success: (data) => {
            let objects = this.state.objects;
            data.forEach(val => {
              const index = objects.findIndex(x => parseInt(x.id) === val.id);
              if (index !== -1) {
                objects[index] = val;
              }
            });
            this.setState({
              selectedItems: [],
              bulkSelect: false,
              objects: objects
            });
            return true;
          },
          error: (data) => {
            return false;
          }
        });
      }
    }]);
  }

  onImport = () => {
    this.setState({importFile: null});
    triggerEvent('showContentPopup', [{
      title: 'Import CSV',
      content: <FileInput
        key={Date.now()}
        onChange={(k, val) => this.setState({importFile: val})}
        properties={{
          accept: '.csv'
        }}
      />,
      buttonText: 'Import',
      callback: result => {
        if (result && this.state.importFile) {
          const formData = new FormData();
          formData.append('file', this.state.importFile);
          sendRequest({
            method: this.state.config.import.method,
            type: 'POST',
            formData,
            success: (data) => {
              triggerEvent('showSnackbar', [{text: 'New records will be added soon', type: 'success'}]);
              this.requestData();
            },
            error: (data) => {
            }
          });
          return true;
        }
      }
    }]);
  }

  requestCsvData = (search) => {
    sendRequest({
      method: this.state.config.exportMethod,
      data: search,
      type: 'GET',
      success: (data) => {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const fileName = `${this.state.config.title}.csv` || 'search.csv'
        saveAs(csvData, fileName);
      },
      error: (data) => {
      }
    });
  }

  onObjectDelete = (object) => {
    const deletionMsg = this.state.config.deletionMsg || ''
    triggerEvent('showConfirmation', [{
      title: `Are you sure want to delete ${this.state.config.objectName} #${object.id}? ${deletionMsg}`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      callback: confirm => {
        if (confirm) {
          this.onDeleteConfirm(object);
        }
      }
    }]);
  }

  onDeleteConfirm = (object) => {
    sendRequest({
      method: this.state.config.method + object.id,
      type: "DELETE",
      success: (data) => {
        this.requestData();
      },
      error: (data) => {
      }
    });
  }

  onSearch = (search, initial) => {
    let data = {...this.state.search};
    Object.keys(search).forEach(key => {
      data[key] = search[key] === undefined ? null : search[key];
    });
    this.setState({page: initial ? this.state.page : 0, search: data}, () => {
      this.requestData();
    });
  }

  onObjectEdit = (object) => {
    this.props.history.push(`/${this.props.configKey}/${object.id}`);
  }

  onObjectCreate = (e) => {
    this.props.history.push(`/${this.props.configKey}/create`);
  }

  onBulkActions = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      open: !this.state.open,
    });
  }

  onObjectChange = (id, key, value) => {
    const index = this.state.objects.findIndex(i => i.id === id);
    this.setState({objects:
      update(this.state.objects, {
        [index]: {
          [key]: {$set: value}
        }
      })
    });
  }

  onOptionClick = (e, option) => {
    e.stopPropagation();
    e.preventDefault();
    if (option.disabled) {
      return;
    }
    if (option.onClick) {
      option.onClick();
    }
    this.setState({open: false});
  }

  onElemSelect = (value) => {
    if (this.state.selectedItems.includes(value)) {
      this.setState({ selectedItems: this.state.selectedItems.filter(item => item !== value) });
    } else {
      this.setState(prevState => ({
        selectedItems: [...prevState.selectedItems, parseInt(value)]
      }));
    }
  }

  onSelectAllClick = (value) => {
    this.setState({
      selectedAll: value,
    }, () => this.requestData(this.onSelectAll));
  }

  onSelectAll = () => {
    this.setState({ selectedItems: this.state.selectedAll ? this.state.objectIds : [] });
  }

  renderListColumn = (key) => {
    const columnProps = this.state.properties[key];
    const sortKey = columnProps.sortAs ? columnProps.sortAs : key;
    const order = columnProps.sort && this.state.sort === sortKey ? this.state.sort_order : null;
    return (
      <th
        key={key}
        width={columnProps.width}
        className={columnProps.sort ? 'sortable' : ''}
        onClick={columnProps.sort ? () => {
          this.setState({
            sort: sortKey,
            sort_order: order === 'desc' ? 'asc' : 'desc',
          }, () => this.requestData())
        } : null}
      >
        {this.state.properties[key].title}
        {order ?
          <div
            className='sortIcon'
            style={{
              transform: order === 'asc' ? 'scaleY(-1)' : null,
            }}
          >
            <span className='material-icons'>sort</span>
          </div>
        : null}
      </th>
    )
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => {
          this.setState({page: page - 1}, this.requestData);
        }}
      />
    )
  }

  renderSearch = () => {
    if (this.state.config.search || this.state.config.exportMethod) {
      return (
        <SearchView
          initialValue={this.state.search}
          config={this.state.config.search || {}}
          onSearch={this.onSearch}
          missedObjects={this.state.missedObjects}
          onExport={this.state.config.exportMethod ?
            search => this.requestCsvData(search)
          : null}
        />
      )
    }
    return null;
  }

  renderObjects = () => {
    return (
      this.state.objects.map(object =>
        <ObjectListElem
          key={object.id}
          configKey={this.props.configKey}
          onDelete={this.onObjectDelete}
          onEdit={this.onObjectEdit}
          onChange={this.onObjectChange}
          options={this.state.options}
          object={object}
          selectedItems={this.state.selectedItems}
          bulkSelect={this.state.bulkSelect}
          onElemSelect={this.onElemSelect}
        />
      )
    )
  }

  renderMenu = () => {
    const config = this.state.config;
    const options = [
      { title: 'Create', onClick: () => this.onObjectCreate() },
      config.import ? { title: config.import.title || 'Import CSV', onClick: () => this.onImport() } : null,
      config.bulkActions ? { title: 'Bulk Edit', onClick: () => this.setState({ bulkSelect: true }) } : null,
    ].filter(i => !!i);

    return (
      <div
        className={classnames({
          'menu': true,
          'hidden': !this.state.open,
        })}
      >
        {options.map(option =>
          <div
            key={option.title}
            className={classnames({
              'menuItem': true,
              'danger': option.danger,
              'disabled': option.disabled,
            })}
            onClick={e => this.onOptionClick(e, option)}
          >
            <div className='menuItemTitle'>{option.title}</div>
            {option.description ?
              <div
                className='menuItemDescription'
                dangerouslySetInnerHTML={{__html:option.description}}
              />
            : null}
          </div>
        )}
      </div>
    )
  }

  renderBulkSelectActions = () => {
    return (
      <div className='bulkSelect'>
        {this.props.isMobile
          ? <div  className='selectAllMobile'>
              <CheckboxInput
                object={this.state.selectedAll}
                onChange={(k, value) => this.onSelectAllClick(value)}
                tiny
              />
              <span>Select All</span>
            </div>
          : null}
        <div className='selectItems'>Select items to bulk edit</div>
        <button
          className='selectEdit'
          disabled={this.state.selectedItems.length === 0}
          onClick={() => this.onBulk()}
        >
          Bulk edit {this.state.selectedItems.length} selections
        </button>
      </div>
    );
  }

  render = () => {
    let config = this.state.config;

    // Objects
    let columns = Object.keys(this.state.properties)
      .filter(key => !!this.state.properties[key].width)
      .filter(key => {
        let condition = this.state.properties[key].listCondition;
        return !condition || condition()
      })
      .map(this.renderListColumn);

    let editColumn = <th width="44px"></th>;
    let deleteColumn = <th width="44px"></th>;
    let addButton = <div
      className='createButton'
      onClick={this.onObjectCreate}
    >
      <span className='material-icons'>add</span>
    </div>
    if (config.modifyCondition && !config.modifyCondition()) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (config.hideActions) {
      addButton = null;
      editColumn = null;
      deleteColumn = null;
    }
    if (config.disableAdd || !this.props.user.admin_write) {
      addButton = null;
    }
    if (config.bulkActions) {
      addButton = <div
        className='createButton'
        onClick={(e) => this.onBulkActions(e)}
      >
        <span className='material-icons'>more_horiz</span>
      </div>
    }

    if (config.disableDelete || !this.props.user.admin_write) {
      deleteColumn = null;
    }

    return (
      <div className='objectListView'>

        {this.renderSearch()}

        {this.props.isMobile
          ? <>
              {this.state.bulkSelect
                ? <div className='card'>{this.renderBulkSelectActions()}</div>
                : null}
              {this.renderObjects().map((elem, index) =>
                <div key={index} className='card'>{elem}</div>
              )}
            </>
          : <div className='card'>
              {this.state.bulkSelect
                ? this.renderBulkSelectActions()
                : null}

              <table className='objectsListTable'>
                <thead>
                  <tr>
                    {this.state.bulkSelect && this.state.objects.length
                      ? <th width='60px'>
                          <CheckboxInput
                            object={this.state.selectedAll}
                            onChange={(k, value) => this.onSelectAllClick(value)}
                            tiny
                          />
                        </th>
                      : null}
                    {columns}
                    {editColumn}
                    {deleteColumn}
                  </tr>
                </thead>
                <tbody>
                  {this.renderObjects()}
                </tbody>
              </table>
            </div>
        }
        {this.renderPagination()}
        {addButton}
        {this.renderMenu()}
        {config.import && this.props.user.admin_write && !config.bulkActions ?
          <button
            className='importButton'
            onClick={this.onImport}
          >{config.import.title || 'Import CSV'}
          </button>
        : null}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectListView));
