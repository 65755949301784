import React from 'react';

import SelectInput from './SelectInput';
import TextInput from './TextInput';
import CheckboxInput from './CheckboxInput';

import '../../sass/components/input/SurveyQuestionsView.scss';

const TYPES = [
  { id: 'star', name: 'Star' },
  { id: 'rate', name: 'Rate' },
  { id: 'opened', name: 'Opened' },
  { id: 'opened_short', name: ' Opened short' },
  { id: 'yes_no', name: 'Yes/No' },
  { id: 'multiple_choice', name: 'Multiple choice' },
];

export default class SurveyQuestionsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  updateItem = (id, key, value) => {
    this.handleChange(this.props.object.map(i =>
      i.id === id ? { ...i, [key]: value } : i
    ));
  }

  deleteItem = (id) => {
    this.handleChange(this.props.object.filter(i =>
      i.id !== id
    ));
  }

  moveItem = (index, delta) => {
    const object = [...this.props.object];
    const [ item ] = object.splice(index, 1);
    object.splice(index + delta, 0, item);
    this.handleChange(object);
  }

  updateOption = (item, index, value) => {
    const options = item.options ? [...item.options] : [];
    options[index] = value;
    this.updateItem(item.id, 'options', options);
  }

  removeOption = (item, index) => {
    const options = item.options ? [...item.options] : [];
    options.splice(index, 1);
    this.updateItem(item.id, 'options', options);
  }

  addOption = (item) => {
    const options = item.options ? [...item.options, ''] : [''];
    this.updateItem(item.id, 'options', options);
  }

  renderOptions = (item) => {
    const options = item.options || [];
    return (
      <div className='optionsContainer'>
        {options.map((option, index) =>
          <div
            key={index}
            className='optionItem'
          >
            <div
              className='removeButton'
              onClick={() => this.removeOption(item, index)}
            >
              <span className='material-icons'>close</span>
            </div>
            <TextInput
              object={option}
              onChange={(k, val) => this.updateOption(item, index, val)}
              properties={{type: 'text', placeholder: `Answer #${index+1}`}}
            />
          </div>
        )}
        <div
          className='addButton'
          onClick={() => this.addOption(item)}
        >
          <span className='material-icons'>add</span>
        </div>
      </div>
    )
  }

  renderItem = (item, index) => {
    return (
      <div
        key={item.id}
        className='questionItem card'
      >
        {index > 0 ?
          <div
            className='upButton'
            onClick={() => this.moveItem(index, -1)}
          >
            <span className='material-icons'>expand_less</span>
            <div className='tooltip'>Move&nbsp;up</div>
          </div>
        : null}
        {index < this.props.object.length - 1 ?
          <div
            className='downButton'
            onClick={() => this.moveItem(index, 1)}
          >
            <span className='material-icons'>expand_more</span>
            <div className='tooltip'>Move&nbsp;down</div>
          </div>
        : null}
        <div
          className='removeButton'
          onClick={() => this.deleteItem(item.id)}
        >
          <span className='material-icons'>close</span>
        </div>
        <div className='questionIndex'>#{index + 1}</div>
        <SelectInput
          object={item.question_type}
          onChange={(k, val) => this.updateItem(item.id, 'question_type', val)}
          properties={{ options: TYPES, placeholder: 'Question type' }}
        />
        {item.question_type ? <>
          <div className='questionLabel'>Title</div>
          <TextInput
            object={item.title}
            onChange={(k, val) => this.updateItem(item.id, 'title', val)}
            properties={{type: 'text'}}
          />
          <div className='questionLabel'>Subtitle</div>
          <TextInput
            object={item.subtitle}
            onChange={(k, val) => this.updateItem(item.id, 'subtitle', val)}
            properties={{type: 'textarea'}}
          />
          <div className='questionLabel'>Required</div>
          <CheckboxInput
            object={item.required}
            onChange={(k, val) => this.updateItem(item.id, 'required', val)}
            //tiny
          />
        </> : null}
        {item.question_type === 'multiple_choice' ? <>
          <div className='questionLabel'>Options</div>
          {this.renderOptions(item)}
        </> : null}
      </div>
    )
  }

  render = () => {
    const object = this.props.object;
    return (
      <div className='surveyQuestionsView'>
        <div className='itemsContainer'>
          {object.map(this.renderItem)}
        </div>
        <button
          onClick={() => this.handleChange([...object, { id: `$${Date.now()}` }])}
        >Add Question</button>
      </div>
    )
  }

}
