import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent, eventOn } from './helpers/global.js';
import Storage from './helpers/Storage.js';
import appConfig from './config/';
import NotificationsController from './helpers/NotificationsController';

import LoginView from './components/LoginView.js';
import HeaderView from './components/HeaderView.js';
import LoadIndicator from './components/LoadIndicator';
import SelectOptionPopup from './components/common/SelectOptionPopup';
import ConfirmationPopup from './components/common/ConfirmationPopup';
import ContentPopup from './components/common/ContentPopup';
import BulkEditPopup from './components/common/BulkEditPopup';
import FramePopup from './components/common/FramePopup';
import NotificationPopup from './components/common/NotificationPopup';
import ResponseToClientPopup from './components/common/ResponseToClientPopup';
import Snackbar from './components/common/Snackbar';

import ObjectListView from './components/ObjectListView.js';
import ObjectEditView from './components/ObjectEditView.js';

import SettingsView from './components/SettingsView.js';
import ReportsView from './components/ReportsView.js';
import NotificationsView from './components/NotificationsView.js';

const adminListRoutes = Object.keys(appConfig).map(key => `/${key}`);
const adminEditRoutes = Object.keys(appConfig).map(key => `/${key}/:id`);

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    if (!rest.user) return <Redirect to='/login'/>
    const key = props.location.pathname.substr(1).split('/')[0];
    if (!appConfig[key] && ['notifications'].indexOf(key) < 0) return <Redirect to='/users'/>
    if (appConfig[key].config.adminOnly && !rest.user.admin) return <Redirect to='/users'/>
    return <Component {...props} configKey={key}/>
  }}/>
)

const LoginRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    !rest.user ? <Component {...props}/> : <Redirect to='/users'/>
  )}/>
)

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showLoadIndicator: false,
    };
    this.loadCount = 0;

    eventOn('addLoad', this.addLoad);
    eventOn('removeLoad', this.removeLoad);
    eventOn('unauthorized', this.onUnauthorized);
    eventOn('forbidden', this.onForbidden);

    eventOn('resize', (e) => {
      Storage.setSetup('is_mobile', window.innerWidth <= 768);
    }, window);
    Storage.setSetup('is_mobile', window.innerWidth <= 768);
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  addLoad = () => {
    this.loadCount++;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  removeLoad = () => {
    this.loadCount--;
    if (this.state.showLoadIndicator !== (this.loadCount > 0)) {
      this.setState({showLoadIndicator: this.loadCount > 0});
    }
  }

  onLogout = (unauthorized) => {
    if (!unauthorized) {
      sendRequest({
        type: 'POST',
        method: 'me/logout',
      });
    }
    Storage.setData('user', null);
    localStorage.removeItem('safespace-admin:token');
  }

  onUnauthorized = () => {
    triggerEvent('showSnackbar', [{text: 'Your access token has been expired. Please re-login.', type: 'error'}]);
    this.onLogout(true);
  }

  onForbidden = () => {
    triggerEvent('showSnackbar', [{text: 'You must be an admin to access this page.', type: 'error'}]);
    this.props.history.goBack();
  }

  componentDidMount = () => {
    if (this.props.user) {
      sendRequest({
        method: 'me/',
        type: 'GET',
        success: (data) => {
          Storage.setData('user', data);
        },
        error: (data) => {
        }
      });
    }
  }

  render = () => {
    const user = this.props.user;
    const hideUI = false;
    return (
      <div>
        {hideUI ? null : <HeaderView
          onLogout={this.onLogout}
        />}
        <div className={classnames({
          'adminContent': true,
          'customScrollBar': true,
          'showUI': !hideUI,
        })}>
          <Switch>
            <AdminRoute user={user} path='/notifications' component={NotificationsView}/>
            <AdminRoute user={user} path='/settings' component={SettingsView}/>
            <AdminRoute user={user} path='/app_settings' component={ObjectEditView}/>
            <AdminRoute user={user} path='/reports' component={ReportsView}/>
            <AdminRoute user={user} path={adminEditRoutes} component={ObjectEditView}/>
            <AdminRoute user={user} path={adminListRoutes} component={ObjectListView}/>
            <LoginRoute user={user} path='/' component={LoginView}/>
          </Switch>
        </div>

        <LoadIndicator show={this.state.showLoadIndicator}/>
        <NotificationsController/>
        <ConfirmationPopup global/>
        <SelectOptionPopup global/>
        <ContentPopup global/>
        <BulkEditPopup global/>
        <FramePopup global/>
        <ResponseToClientPopup global/>
        <NotificationPopup/>
        <Snackbar/>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(App));
