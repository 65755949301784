import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import '../../sass/components/input/AppointmentUserInfo.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class AppointmentUserInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderAppointmentLink = (item) => {
    return (
      <td>
        <span
          onClick={() => this.props.history.push(`/appointments/${item.id}`)}
        >#{item.id}</span>
      </td>
    )
  }

  renderInfoItem = (item) => {
    if (this.props.isMobile) {
      return (
        <table key={item.id} className='card'>
          <tbody>
            <tr>
              <td>Appointment</td>
              {this.renderAppointmentLink(item)}
            </tr>
            <tr>
              <td>Specializations</td>
              <td>{item.specializations}</td>
            </tr>
            <tr>
              <td>Feelings</td>
              <td>{item.feelings}</td>
            </tr>
            <tr>
              <td>Experience</td>
              <td>{item.experience}</td>
            </tr>
            <tr>
              <td>Appt date/time</td>
              <td>{moment.unix(item.planned_at).format('DD.MM.YYYY HH:mm')}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td className='statusRow'>{item.status}</td>
            </tr>
          </tbody>
        </table>
      )
    } else {
      return (
        <tr key={item.id}>
          {this.renderAppointmentLink(item)}
          <td>{item.feelings}</td>
          <td>{item.feeling_reasons}</td>
          <td>{item.symptoms}</td>
          <td>{moment.unix(item.planned_at).format('DD.MM.YYYY HH:mm')}</td>
          <td className='statusRow'>{item.status}</td>
        </tr>
      )
    }
  }

  render = () => {
    const items = this.props.object || [];
    return (
      <div className='appointmentUserInfo'>
        {this.props.isMobile
          ? items.map(this.renderInfoItem)
          : <table>
              <thead>
                <tr>
                  <th width="110px">Appointment</th>
                  <th>Feelings</th>
                  <th>Feeling reasons</th>
                  <th>Symptoms</th>
                  <th>Appt date/time</th>
                  <th>Status</th>
                </tr>
              </thead>
            <tbody>
              {items.map(this.renderInfoItem)}
            </tbody>
          </table>
        }
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(AppointmentUserInfo));
