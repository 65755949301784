import React from 'react';

import '../../sass/components/input/LabelsList.scss';

export default class LabelsList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='labelsList'>
        {object.map((i, index) =>
          <div key={index} className='labelItem'>{i}</div>
        )}
      </div>
    )
  }

}
