import React from 'react';
import moment from 'moment';
import classnames from 'classnames';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import '../../sass/components/input/UserChats.scss';

export default class UserChats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  showChatContent = (id, chatItem, title) => {
    sendRequest({
      method: `chats/${id}`,
      type: 'GET',
      success: (data) => {
        triggerEvent('showContentPopup', [{
          title,
          content: this.renderChatContent(chatItem, data),
          buttonText: 'Close',
        }]);
      },
      error: (data) => {
      }
    });
  }

  renderChatContent = (chatItem, data) => {
    return (
      <div className='chatContent customScrollBar'>
        {data.messages.map((message, index) => this.renderChatMessage(message, index, chatItem))}
      </div>
    )
  }

  renderChatMessage = (message, index, chatItem) => {
    const parentId = this.props.parentObject.id;
    const author = message.author || {};
    const urlRegex = RegExp('((?!mailto:)(?:(?:http|https|ftp)://)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?)', 'ig');
    const text = message.content ?
      message.content
        .replace(/\n/g, '<br/>')
        .replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>')
    : null;
    const file = message.file || null;
    const appointment = message.appointment || null;
    const system = !!message.appointment;
    const owned = !system && author.id === parentId;
    return (
      <div
        key={index}
        data-id={message.id}
        className='messageContainer'
      >
        <div
          className={classnames({
            'messageItem': true,
            'owned': owned,
            'system': system,
          })}
        >
          <div className='messageInfo'>
            <div className='messageTime'>{moment.unix(message.created_at).format('HH:mm DD.MM.YYYY')}</div>
            {system
              ? <div className='messageAuthor'>System</div>
              : author.email
                ? <div className='messageAuthor'>{author.name} / {author.email}</div>
                : <div className='messageAuthor'>
                    <span>{author.name}</span>
                  </div>
            }
          </div>
          {text ?
            <div className='messageContent' dangerouslySetInnerHTML={{__html: text}}/>
          : null}
          {file ?
            <div className='fileLink' onClick={() => window.open(file.url, '_blank')}>{file.name}</div>
          : null}
          {appointment ?
            <div className='messageAppointment'>
              <div className={`appointmentText ${appointment.status}`}>
                {appointment.status === 'pending' ?
                  `Appointment planned for ${moment.unix(appointment.planned_at).format('DD.MM.YYYY HH:mm')}`
                : appointment.status === 'confirmed' ?
                  `Appointment for ${moment.unix(appointment.planned_at).format('DD.MM.YYYY HH:mm')} was confirmed`
                : appointment.status === 'rejected' ?
                  `Appointment for ${moment.unix(appointment.planned_at).format('DD.MM.YYYY HH:mm')} was rejected`
                : null}
              </div>
            </div>
          : null}
        </div>
      </div>
    )
  }

  renderChatItem = (item) => {
    const parentRole = this.props.parentObject.role;
    const participant = (parentRole === 'therapist' ? item.patient : item.therapist) || {};
    const title = participant.name || `#${participant.id}`;
    return (
      <div
        key={item.id}
        className='chatItem'
        onClick={() => this.showChatContent(item.id, item, title)}
      >
        {title}
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='userChats'>
        {object.map(this.renderChatItem)}
      </div>
    )
  }

}
