export default {
  section: "Data",
  config: {
    method: "users/",
    title: "Users",
    objectName: "User",
    disableDelete: true,
    bulkActions: true,
    exportMethod: "users/csv_export",
    bulkEditMethod: "users/bulk",
    editView: require('../components/editControls/EditViewWithLogs.js').default,
    import: {
      method: "users/import_patients",
      title: "Import Clients"
    },
    search: {
      name: {
        type: "text",
        title: "Name",
      },
      email: {
        default: "",
        type: "multi-text",
        title: "Email",
        validation: "users/validate",
      },
      role: {
        default: "patient",
        type: "select",
        title: "Role",
        options: [
          { id: 'patient', name: 'Patient' },
          { id: 'therapist', name: 'Therapist' },
          { id: 'corporate', name: 'Corporate' },
        ],
      },
      corporate_user_id: {
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
    },
    bulk: {
      approved: {
        default: false,
        type: "checkbox",
        title: "Approved",
      },
      admin: {
        default: false,
        type: "checkbox",
        title: "Admin",
      },
      active: {
        default: false,
        type: "checkbox",
        title: "Active",
      },
      messages_app_notifications: {
        default: false,
        type: "checkbox",
        title: "App notifications about messages",
      },
      messages_email_notifications: {
        default: false,
        type: "checkbox",
        title: "Email notifications about messages",
      },
      appointments_app_notifications: {
        default: false,
        type: "checkbox",
        title: "App notifications about appointments",
      },
      appointments_email_notifications: {
        default: false,
        type: "checkbox",
        title: "Email notifications about appointments",
      },
      country_id: {
        default: null,
        type: "select",
        title: "Country",
        request: "common_data/countries",
      },
      date_format: {
        default: null,
        type: "select",
        title: "Date format",
        options: [
          { id: 'dd_mm_yyyy', name: 'DD-MM-YYYY' },
          { id: 'dd_mm_yy', name: 'DD-MM-YY' },
          { id: 'yyyy_mm_dd', name: 'YYYY-MM-DD' },
        ],
      },
      timezone_id: {
        default: null,
        type: "select",
        title: "Timezone",
        request: "common_data/timezones",
      },
      corporate_user_id: {
        default: null,
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      promocode_id: {
        default: null,
        type: "select",
        title: "Promocode",
        clearable: true,
        async: true,
        request: "promocodes/search",
        searchKey: "code",
      },
      corporate_site_id: {
        default: null,
        type: "select",
        title: "Site",
        clearable: true,
        async: true,
        request: "corporate_sites/search",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    role: {
      default: "patient",
      type: "select",
      title: "Role",
      options: [
        { id: 'patient', name: 'Patient' },
        { id: 'therapist', name: 'Therapist' },
        { id: 'corporate', name: 'Corporate' },
      ],
      width: "auto"
    },
    email: {
      default: "",
      type: "text",
      title: "Email",
      width: "auto",
    },
    url_key: {
      type: "text",
      title: "URL key",
      preset: true,
      showCondition: object => object.role === 'therapist',
    },
    password: {
      type: "password",
      title: "Password",
    },
    approved: {
      type: "checkbox",
      title: "Approved",
      width: "80px",
    },
    admin: {
      type: "checkbox",
      title: "Admin",
      width: "80px",
    },
    active: {
      type: "checkbox",
      title: "Active",
      width: "80px",
    },
    messages_app_notifications: {
      default: false,
      type: "checkbox",
      title: "App notifications about messages",
    },
    messages_email_notifications: {
      default: false,
      type: "checkbox",
      title: "Email notifications about messages",
    },
    appointments_app_notifications: {
      default: false,
      type: "checkbox",
      title: "App notifications about appointments",
    },
    appointments_email_notifications: {
      default: false,
      type: "checkbox",
      title: "Email notifications about appointments",
    },
    address: {
      default: "",
      type: "text",
      title: "Address",
    },
    country_id: {
      default: null,
      type: "select",
      title: "Country",
      request: "common_data/countries",
      // showCondition: object => object.role === 'patient',
    },
    date_format: {
      type: "select",
      title: "Date format",
      options: [
        { id: 'dd_mm_yyyy', name: 'DD-MM-YYYY' },
        { id: 'dd_mm_yy', name: 'DD-MM-YY' },
        { id: 'yyyy_mm_dd', name: 'YYYY-MM-DD' },
      ],
    },
    manual_timezone: {
      default: false,
      type: "checkbox",
      title: "Manual timezone",
    },
    timezone_id: {
      default: null,
      type: "select",
      title: "Timezone",
      request: "common_data/timezones",
    },
    two_fa_enabled: {
      type: "checkbox",
      default: false,
      title: "2FA enabled",
    },

    //therapist
    bio: {
      type: "textarea",
      title: "Personal bio",
      wordLimit: 100,
      showCondition: object => object.role === 'therapist',
    },
    video_url: {
      type: "text",
      title: "Video URL",
      showCondition: object => object.role === 'therapist',
    },
    suicide_intervention_certified: {
      default: false,
      type: "checkbox",
      title: "Suicide Intervention certified",
      showCondition: object => object.role === 'therapist',
    },
    intern: {
      type: "checkbox",
      title: "Is Intern",
      showCondition: object => object.role === 'therapist',
    },
    intern_bio: {
      type: "textarea",
      title: "Intern bio",
      showCondition: object => object.role === 'therapist' && object.intern,
    },
    language_ids: {
      type: "multi-select",
      title: "Languages",
      request: "common_data/languages",
      showCondition: object => object.role === 'therapist',
    },
    profession_ids: {
      type: "multi-select",
      title: "Professions",
      request: "common_data/professions",
      showCondition: object => object.role === 'therapist',
    },
    face_to_face_enabled: {
      type: "switch",
      title: "Face to face sessions",
      showCondition: object => object.role === 'therapist',
    },
    session_settings: {
      type: "session-settings",
      title: "Face to face settings",
      counselling_type: "face_to_face",
      showCondition: object => object.role === 'therapist' && object.face_to_face_enabled,
    },
    video_call_enabled: {
      type: "switch",
      title: "Video call sessions",
      showCondition: object => object.role === 'therapist',
    },
    _session_settings: {
      type: "session-settings",
      title: "Video call settings",
      counselling_type: "video_call",
      showCondition: object => object.role === 'therapist' && object.video_call_enabled,
    },
    location_photos: {
      type: "photos",
      title: "Location photos",
      createRequest: "users/:id/location_photo",
      deleteRequest: "users/:id/location_photo/:item_id",
      showCondition: object => object.role === 'therapist',
    },
    image: {
      type: "image",
      title: "Avatar",
      uploadRequest: "users/:id",
      deleteRequest: "users/:id",
      showCondition: object => object.role === 'therapist',
    },
    google_map_location_url: {
      default: "",
      type: "text",
      title: "Google map location URL",
      showCondition: object => object.role === 'therapist',
    },
    sessions_buffer_mins: {
      default: 0,
      type: "select",
      title: "Break after session",
      options: [
        { id: 0, name: 'no break' },
        { id: 15, name: '15 min' },
        { id: 30, name: '30 min' },
        { id: 45, name: '45 min' },
        { id: 60, name: '60 min' },
      ],
      showCondition: object => object.role === 'therapist',
    },
    ahead_booking_limit: {
      type: "select",
      title: "Advance booking limit",
      options: [
        { id: 0, name: '24 hours ahead' },
        { id: 0.08, name: '2 hours ahead' },
        { id: 2, name: '2 days ahead' },
        { id: 3, name: '3 days ahead' },
        { id: 4, name: '4 days ahead' },
        { id: 5, name: '5 days ahead' },
        { id: 7, name: '1 week ahead' },
        { id: 14, name: '2 weeks ahead' },
        { id: 21, name: '3 weeks ahead' },
        { id: 28, name: '4 weeks ahead' },
      ],
      showCondition: object => object.role === 'therapist',
    },

    // corporate
    corporate_size: {
      type: "number",
      title: "Corporate size",
      showCondition: object => object.role === 'corporate',
    },
    corporate_two_fa_enabled: {
      type: "checkbox",
      default: false,
      title: "2FA for employees enabled",
      showCondition: object => object.role === 'corporate',
    },

    // patient
    phone: {
      default: "",
      type: "text",
      title: "Phone",
      showCondition: object => object.role === 'patient',
    },
    phone_country_code: {
      default: "65",
      type: "select",
      title: "Phone country code",
      request: "common_data/phone_country_codes",
      showCondition: object => object.role === 'patient',
    },
    emergency_phone: {
      default: "",
      type: "text",
      title: "Emergency Phone",
      showCondition: object => object.role === 'patient',
    },
    emergency_contact: {
      default: "",
      type: "text",
      title: "Emergency Contact",
      showCondition: object => object.role === 'patient',
    },
    birthdate: {
      default: null,
      type: "date",
      title: "Birthday",
      showCondition: object => object.role === 'patient',
    },
    corporate_user_id: {
      default: null,
      type: "select",
      title: "Corporate",
      clearable: true,
      async: true,
      request: "users/search?role=corporate",
      showCondition: object => object.role === 'patient',
    },
    corporate_site_id: {
      default: null,
      type: "select",
      title: "Site",
      clearable: true,
      async: true,
      request: "corporate_sites/search",
      showCondition: object => object.role === 'patient',
    },
    appointments: {
      default: null,
      type: "appointment-user-info",
      title: "Appointments info",
      showCondition: object => object.role === 'patient' && object.appointments?.length > 0,
    },
    chats: {
      default: [],
      type: "user-chats",
      title: "Chats",
      showCondition: object => !!(object.chats || []).length,
    },
    managed: {
      type: "checkbox",
      default: false,
      title: "Managed plan",
      showCondition: object => object.role === 'corporate',
    },
  }
};
