import React from 'react';
import CreatableSelect from 'react-select/creatable';

import { sendRequest } from '../../helpers/global';

import '../../sass/components/input/MultiTextInput.scss';

export default class MultiTextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  getMultipleTextInputValues = () => {
    const object = this.props.object;
    if (Array.isArray(object)) {
      return object;
    } else if (typeof object === 'string' && object !== '') {
      return object.split(',');
    } else {
      return [];
    }
  }

  validateInput = (val) => {
    const key = this.props.objectKey;
    if (!val) {
      if (this.props.onValidation) {
        this.props.onValidation({[key]: []}, key);
      }
      return;
    }

    let formData = new FormData();
    formData.append('id', this.props.parentObject && this.props.parentObject.id);
    formData.append('field', key);
    formData.append(key, val);

    sendRequest({
      type: 'POST',
      method: this.props.properties.validation,
      data: formData,
      noLoad: true,
      success: (data) => {
        if (this.props.onValidation) {
          this.props.onValidation(data, key);
        }
      },
      error: (data) => {
      }
    });
  }

  onInputChange = (inputValue) => {
    if (/\s+/.test(inputValue)) {
      this.setState({ inputValue: '' });
      const inputValues = inputValue.split(/\s+/).map(item => item.trim()).filter(val => val !== '');
      let values = this.getMultipleTextInputValues();
      values = values.concat(inputValues);
      if (this.props.onChange) {
        this.props.onChange(this.props.objectKey, values);
      }
      if (this.props.properties.validation) {
        const value = Array.isArray(values) ? values[values.length - 1] : values;
        this.validateInput(value);
      }
    } else {
      this.setState({ inputValue });
    }
  }

  handleCreatableMultiselectChange = (inputValue, actionMeta) => {
    const values = inputValue && inputValue.map(x => x.value);
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, values);
    }
    if (this.props.properties.validation) {
      const value = Array.isArray(values) ? values[values.length - 1] : values;
      this.validateInput(value);
    }
  }

  renderCreatableMultiselectInput = () => {
    const values = this.getMultipleTextInputValues();
    const tags = values.map((str, index) => ({ value: str, label: str }));
    return (
      <CreatableSelect
        isMulti
        value={tags}
        inputValue={this.state.inputValue}
        onChange={this.handleCreatableMultiselectChange}
        onInputChange={this.onInputChange}
        placeholder={this.props.properties.placeholder || ''}
        className='reactSelect'
        classNamePrefix='reactSelect'
      />
    );
  }

  render = () => {
    return (
      <div className='multiTextInput'>
        {this.renderCreatableMultiselectInput()}
      </div>
    )
  }
}
