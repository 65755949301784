import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Datetime from 'react-datetime';

import '../../sass/components/input/DateInput.scss';

export default class DateInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (e) => {
    if (typeof e === 'object') {
      const properties = this.props.properties;
      const object = e 
        ? properties.type === 'datetime' ? e.unix() : e.format('YYYY-MM-DD')
        : null;
      this.props.onChange(this.props.objectKey, object);
    } else if (!e) {
      this.props.onChange(this.props.objectKey, null);
    }
  }

  render = () => {
    const properties = this.props.properties;
    const object = this.props.object;
    const value = properties.type === 'datetime' ? object * 1000 : moment(object);
    return (
      <div
        className={classnames({
          'dateInput': true,
          'simplified': properties.simplified,
        })}
      >
        <Datetime
          value={value}
          onChange={this.handleChange}
          inputProps={{disabled: this.props.disabled, placeholder: this.props.placeholder}}
          dateFormat='DD.MM.YYYY'
          timeFormat={properties.type === 'datetime' ? 'HH:mm' : false}
          closeOnSelect={true}
        />
      </div>
    )
  }
}
