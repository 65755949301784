export default {
  section: "Data",
  config: {
    method: "surveys/",
    title: "Surveys",
    objectName: "Survey",
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Updated at",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
      required: true,
    },
    survey_type: {
      type: "select",
      title: "Survey type",
      options: [
        { id: 'post_booking_patient', name: 'Booking additional questions' },
        { id: 'post_session_patient', name: 'Post session patient' },
        { id: 'post_session_therapist', name: 'Post session therapist' },
        { id: 'hr_dashboard_tutorial', name: 'HR Dashboard Tutorial' },
      ],
      width: "auto",
      required: true,
    },
    questions_display: {
      default: "single",
      type: "select",
      title: "Questions display",
      options: [
        { id: 'single', name: 'Single' },
        { id: 'multiple', name: 'Multiple' },
      ],
      required: true,
    },
    active: {
      default: false,
      type: "checkbox",
      title: "Active",
      width: "auto",
    },
    show_index: {
      default: true,
      type: "checkbox",
      title: "Show index",
    },
    show_tip: {
      default: false,
      type: "checkbox",
      title: "Show tip",
    },
    tip_text: {
      default: false,
      type: "wysiwyg",
      toolbar: {
        options: ['inline'],
        inline: {
          options: ['bold', 'italic', 'strikethrough'],
        },
      },
      title: "Tip text",
      showCondition: object => object.show_tip,
    },
    finish_image: {
      default: null,
      type: "image",
      title: "Finish image",
    },
    finish_title: {
      default: "<p>Thank you for using Safe Space:tm:, and for giving us your feedback.</p>",
      type: "wysiwyg",
      toolbar: {
        options: ['inline'],
        inline: {
          options: ['bold', 'italic', 'strikethrough'],
        },
      },
      title: "Finish Title",
    },
    finish_text: {
      default: false,
      type: "wysiwyg",
      toolbar: {
        options: ['inline', 'link'],
        inline: {
          options: ['bold', 'italic', 'strikethrough'],
        },
        link: {
          options: ['link'],
        },
      },
      title: "Finish text",
    },
    questions: {
      default: [],
      type: "survey-questions",
      title: "Questions",
    },
  }
};
