export default {
  section: "Data",
  config: {
    method: "webinars/",
    title: "Event",
    objectName: "Webinar",
    disableDelete: true,
    search: {
      title: {
        default: "",
        type: "text",
        title: "Title",
      },
      status: {
        type: "select",
        title: "Status",
        options: [
          { id: "pending", name: "Draft" },
          { id: "active", name: "Active" },
        ],
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    specialization_ids: {
      type: "multi-select",
      title: "Specialisation",
      request: "common_data/specializations",
      searchKey: "name",
    },
    title: {
      default: "",
      type: "text",
      title: "Title",
      width: "auto",
      required: true,
    },
    therapist_ids: {
      default: null,
      type: "multi-select",
      title: "Counsellors",
      clearable: true,
      async: true,
      request: "users/search?role=therapist",
    },
    therapist_objects: {
      type: "page-links",
      title: "Counsellors",
      page: "/users/:id",
      width: "auto",
      showCondition: object => false
    },
    status: {
      type: "select",
      title: "Status",
      options: [
        { id: "pending", name: "Draft" },
        { id: "active", name: "Active" },
      ],
      width: "auto",
    },
    description: {
      default: "",
      type: "wysiwyg",
      title: "Description",
    },
    image: {
      type: "file",
      title: "Thumbnail",
    },
    advanced_booking_limit: {
      title: "Advanced booking limit",
      type: "select",
      options: [],
    },
    duration_object: {
      title: "Duration",
      type: "duration",
      default: {"duration":0,"duration_type":"minutes"},
    },
    event_type: {
      title: "Event type",
      type: "select",
      options: [
        { id: "webinar", name: "Webinar" },
        { id: "workshop", name: "Workshop" },
      ],
    },
    location: {
      title: "Location",
      type: "multi-select",
      options: [
        { id: 'online', name: 'Online' },
        { id: 'offline', name: 'Offline' },
      ],
    },
    bookings: {
      default: null,
      type: "bookings-info",
      title: "Bookings",
      showCondition: object => object.bookings?.length > 0,
    },
  }
};
