import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendRequest, triggerEvent } from '../../helpers/global.js';

import SelectInput from '../input/SelectInput.js';

import '../../sass/components/editControls/AppointmentControlsView.scss';

const CANCEL_REASONS = [
  { id: 'wrong_therapist', name: 'Wrong therapist' },
  { id: 'wrong_time', name: 'Wrong time' },
  { id: 'wrong_specialty', name: 'Wrong specialty' },
  { id: 'found_alternative', name: 'Found alternative' },
];

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class AppointmentControlsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelReason: null,
    };
  }

  componentDidMount = () => {
  }

  onCancelConfirm = () => {
    sendRequest({
      method: `appointments/${this.props.item.id}/cancel`,
      type: 'POST',
      data: {
        cancel_reason: this.state.cancelReason,
      },
      success: (data) => {
        this.props.onChange(data);
        triggerEvent('showSnackbar', [{text: 'Appointment cancelled', type: 'success'}]);
      },
      error: (data) => {
      }
    });
  }

  cancelAppointment = () => {
    this.setState({cancelReason: null});
    triggerEvent('showContentPopup', [{
      title: 'Please select cancel reason for this appointment?',
      content: <div key={Date.now()}>
        <SelectInput
          onChange={(k, val) => this.setState({cancelReason: val})}
          properties={{
            type: 'select',
            options: CANCEL_REASONS,
          }}
        />
      </div>,
      buttonText: 'Cancel',
      callback: result => {
        if (result) {
          if (this.state.cancelReason) {
            this.onCancelConfirm();
          } else {
            return false;
          }
        }
        return true;
      }
    }]);
  }

  render = () => {
    const item = this.props.item;
    if (!item.status || item.status === 'cancelled' || !this.props.user.admin_write) {
      return null;
    }
    return (
      <div className='appointmentControlsView'>
        <button
          onClick={this.cancelAppointment}
          className='outline'
        >Cancel Appointment</button>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(AppointmentControlsView))
