import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { withRouter } from "react-router-dom";

import { sendRequest } from '../../helpers/global.js';

import TabsView from '../common/TabsView';
import Pagination from '../common/Pagination';

import '../../sass/components/editControls/EditViewWithLogs.scss';

const ITEMS_PER_PAGE = 10;

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class EditViewWithLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'general',
      tabs: props.config.objectName === 'User'
        ? [
            { key: 'general', title: 'General' },
            { key: 'logs', title: 'Logs' },
            { key: 'price_logs', title: 'Price Logs' },
          ]
        : [
            { key: 'general', title: 'General' },
            { key: 'logs', title: 'Logs' },
          ],
      isUserView: props.config.objectName === 'User',
      changes: [],
      page: 0,
      maxPage: 0,
      price_changes: [],
      price_page: 0,
      price_maxPage: 0,
    };
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
    const curItem = this.props.item || {};
    const prevItem = prevProps.item || {};

    if (
      (curItem.id && (curItem.id !== prevItem.id))
      || (this.props.config?.method !== prevProps.config?.method)
    ) {
      this.setState({
        tab: 'general',
        changes: [],
        page: 0,
        maxPage: 0,
      }, () => {
        this.requestData();
        if (this.state.isUserView) this.requestPriceChanges();
        if (!prevItem.id) {
          const tab = this.props.location.hash.replace(/#/g, '');
          if (tab) {
            this.switchTab(tab);
          }
        }
      });
    }
  }

  requestData = () => {
    const item = this.props.item;
    const entity = this.props.config.method.replace(/\//, '');
    if (!item || !item.id) {
      return;
    }
    sendRequest({
      method: 'audit_logs/record_changes',
      type: 'GET',
      data: {
        record_id: item.id,
        entity: entity,
        offset: this.state.page * ITEMS_PER_PAGE,
      },
      success: (data) => {
        const page = this.state.page;
        const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);

        this.setState({
          changes: data.objects,
          maxPage: maxPage,
          page: Math.min(page, maxPage),
        });
      },
      error: (data) => {
      }
    });
  }

  requestPriceChanges = () => {
    const { item } = this.props;
    if (item && item.session_settings) {
      item.session_settings.forEach(setting => {
        sendRequest({
          method: 'audit_logs/record_changes',
          type: 'GET',
          data: {
            record_id: setting.id,
            entity: 'session_settings',
            offset: this.state.price_page * ITEMS_PER_PAGE,
          },
          success: (data) => {
            const page = this.state.price_page;
            const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
    
            this.setState({
              price_changes: [
                ...this.state.price_changes,
                {
                  counselling_type: setting.counselling_type,
                  duration: setting.duration,
                  session_type: setting.session_type,
                  changes: data.objects,
                }
              ],
              price_maxPage: maxPage,
              price_page: Math.min(page, maxPage),
            });
          },
          error: (data) => {
          }
        });
      });
    }
  }

  renderChange = (change) => {
    const author = change.user_list_object;
    return (
      <div key={change.id} className='changeItem'>
        <div className='changeTitle'>
          <div className='action'>{change.action}</div>
          <span>at {moment.unix(change.created_at).format('DD.MM.YYYY HH:mm')}</span>
          <span>by</span>
          {author
            ? <div
              className='author'
              onClick={() => this.props.history.push(`/users/${author.id}`)}
            >{author.name}</div>
            : <b>System</b>
          }
        </div>
        <div className='changeContent'>
          {change.changes_list.map((field, index) =>
            <div key={index} className='fieldItem'>
              <label>{field.field}: </label>
              <span>{`${field.from || ''} -> ${field.to || ''}`}</span>
            </div>
          )}
        </div>
      </div>
    )
  }

  renderPriceChangeGroup = (change) => {
    return (
      <div key={`${change.counselling_type}_${change.session_type}`} className='changeItemGroup'>
        <div className='changeGroupTitle'>{change.counselling_type} {change.session_type}</div>
        {change.changes.map(this.renderChange)}
      </div>
    );
  }

  renderLogsPagination = () => {
    if (this.state.maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.page + 1}
        maxPage={this.state.maxPage}
        onPageChange={page => {
          this.setState({page: page - 1}, this.requestData);
        }}
      />
    )
  }

  renderPriceLogsPagination = () => {
    if (this.state.price_maxPage <= 1) {
      return null;
    }
    return (
      <Pagination
        page={this.state.price_page + 1}
        maxPage={this.state.price_maxPage}
        onPageChange={page => {
          this.setState({price_page: page - 1}, this.requestPriceChanges);
        }}
      />
    )
  }

  render = () => {
    return (
      <div className='editViewWithLogs'>
        <TabsView
          tabs={this.state.tabs}
          tab={this.state.tab}
          onTabChange={tab => this.setState({tab})}
        />
        <div className='card'>
          {this.state.tab === 'general' ? <div className='inputsBlock'>
            {Object.keys(this.props.properties).map(this.props.renderInput)}
          </div> : null}
          {this.state.tab === 'logs' ? <>
            {this.state.changes.map(this.renderChange)}
            {this.renderLogsPagination()}
          </> : null}
          {this.state.isUserView && this.state.tab === 'price_logs' ? <>
            {this.state.price_changes.filter(group => group.changes.length > 0).map(this.renderPriceChangeGroup)}
            {this.renderPriceLogsPagination()}
          </> : null}
        </div>
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(EditViewWithLogs));
