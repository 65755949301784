import React from 'react';

import '../../sass/components/input/ImageInput.scss';
import {sendRequest} from '../../helpers/global';
import {uploadImage} from './PhotosInput';

export default class ImageInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      preview: props.object,
    };
    this.input = null;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.object !== this.props.object &&
      this.props.object?.constructor?.name !== 'File'
    ) {
      this.setState({preview: this.props.object});
    }
  }

  handleChange = (file) => {
    this.setState({preview: URL.createObjectURL(file)});
    this.props.onChange(this.props.objectKey, file);
  }

  componentDidMount = () => {
  }

  handleFile = (file) => {
    const { objectKey, properties, parentObject } = this.props;
    if (!file || !properties.uploadRequest) {
      return;
    }
    uploadImage(properties.uploadRequest.replace(':id', parentObject.id), file,  (data) => {
      this.setState({preview: data[objectKey]});
      this.props.onChange(this.props.objectKey, data[objectKey]);
      }, () => this.input.value = null, 'PUT');
  }

  remove = () => {
    const { properties: {deleteRequest}, objectKey, parentObject } = this.props;
    sendRequest({
      method: deleteRequest.replace(':id', parentObject.id),
      type: 'PUT',
      data: {
        ['remove_' + objectKey]: true
      },
      success: (data) => {
        this.props.onChange(this.props.objectKey, null);
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const preview = this.state.preview;
    var fileName = null;
    if (preview) {
      const fileNameParts = preview.split('/');
      fileName = fileNameParts[fileNameParts.length - 1];
    }
    return (
      <div className='imageInput'>
        <input
          type='file'
          accept='image/x-png,image/gif,image/jpeg'
          ref={input => this.input = input}
          onChange={e => this.props.properties.uploadRequest ? this.handleFile(e.target.files[0])
            : this.handleChange(e.target.files[0])}
        />
        {preview ?
          <div>
            <img alt='' src={preview}/>
          </div>
        : null}
        {fileName ? <p>{fileName}</p> : null}
        <div className='imageButtons'>
          {preview ?
            <button
              className='outline'
              onClick={() => this.props.properties.deleteRequest ? this.remove()
                : this.props.onChange(this.props.objectKey, null)}
            >Remove</button>
          : null}
          {this.props.disabled ? null :
            <button
              className='outline'
              onClick={() => this.input.click()}
            >Select</button>
          }
        </div>
      </div>
    )
  }
}
