export default {
  section: "Booking Settings",
  config: {
    method: "languages/",
    title: "Languages",
    objectName: "Language",
    search: {
      name: {
        type: "text",
        title: "Name",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Language",
      width: "auto",
      required: true,
    },
  }
};
