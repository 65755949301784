import React from 'react';
import classnames from 'classnames';

import { sendRequest } from '../../helpers/global';

import '../../sass/components/input/TagsSelect.scss';

class TagsSelect extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: [],
    };
  }

  componentDidMount = () => {
    if (this.props.options && !this.props.request) {
      this.setState({options: this.props.options});
    }
    if (this.props.request) {
      sendRequest({
        type: 'GET',
        method: this.props.request,
        success: (data) => {
          this.setState({options: data});
        },
        error: (error) => {
        }
      });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.options !== this.props.options) {
      this.setState({options: this.props.options});
    }
    if (prevProps.value !== this.props.value) {
      this.setState({options: this.props.options});
    }
  }

  reorderOptions = (options) => {
    const value = this.props.value;
    return options.sort((a, b) => {
      if (value === a.id && value !== b.id) {
        return -1;
      }
      if (value !== a.id && value === b.id) {
        return 1;
      }
      return 0;
    })
  }

  handleChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  switchSelected = (id) => {
    if (this.props.value === id) {
      this.handleChange(null);
    } else {
      this.handleChange(id);
    }
  }

  renderTag = (tag, index) => {
    return (
      <div
        className={classnames({
          'selected': this.props.value && this.props.value === tag.id,
          'tag': true
        })}
        key={index}
        onClick={() => !this.props.disabled && this.switchSelected(tag.id)}
      >
        {tag.name}
      </div>
    )
  }

  render = () => {
    return (
      <div className='tagsSelect'>
        <div className='tags'>
          {this.state.options ?
            this.state.options.map((tag, i) => this.renderTag(tag, i))
          : null}
        </div>
      </div>
    )
  }
}

export default TagsSelect;
