import React from 'react';

import SwitchInput from './SwitchInput.js';
import TextInput from './TextInput.js';

import '../../sass/components/input/SessionSettingsInput.scss';

export default class SessionSettingsInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  handleChange = (value) => {
    const key = this.props.object ? this.props.objectKey : 'session_settings';
    if (!this.props.disabled) {
      this.props.onChange(key, value);
    }
  }

  updateItem = (id, key, value) => {
    const object = this.props.object || this.props.parentObject.session_settings || [];
    this.handleChange(object.map(i =>
      i.id === id ? {
        ...i,
        [key]: value
      } : i
    ));
  }

  renderSetting = (session_type, duration) => {
    const object = this.props.object || this.props.parentObject.session_settings || [];
    const counselling_type = this.props.properties.counselling_type;
    const item = object.find(i =>
      i.duration === duration &&
      i.session_type === session_type &&
      i.counselling_type === counselling_type
    );
    return (
      <div key={duration} className='settingItem'>
        <span>{session_type}</span>
        <SwitchInput
          object={item?.enabled}
          onChange={(k, val) => this.updateItem(item.id, 'enabled', val)}
        />
        <TextInput
          properties={{type: 'text'}}
          object={item?.price}
          onChange={(k, val) => this.updateItem(item.id, 'price', val)}
        />
      </div>
    )
  }

  render = () => {
    return (
      <div className='sessionSettingsInput'>
        <div className='infoRow'>
          <span>60 min</span>
          <span>90 min</span>
        </div>
        {['individual', 'family', 'couple'].map(session_type =>
          <div key={session_type} className='settingRow'>
            {['60_minutes', '90_minutes'].map(duration =>
              this.renderSetting(session_type, duration)
            )}
          </div>
        )}
      </div>
    )
  }

}
