import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';

import { sendRequest } from '../helpers/global.js';

import SelectInput from './input/SelectInput';
import DateInput from './input/DateInput';

import '../sass/components/ReportsView.scss';

const REPORT_TYPES = [
  { id: 'reports/appointments', name: 'Extract appointments data' },
  { id: 'reports/appointments_corporate_promocodes', name: 'Extract appointments data (corporate promo codes only)' },
  { id: 'reports/therapists_data', name: 'Extract therapists data' },
  { id: 'reports/therapists_scorecards', name: 'Extract therapists scorecards' },
  { id: 'reports/post_booking_patient_survey_results', name: 'Additional booking questions' },
  { id: 'reports/post_session_patient_survey_results', name: 'Post-session survey for clients' },
];

export default class ReportsView extends React.Component {
  constructor(props) {
    super(props);
    const today = moment();
    this.state = {
      type: REPORT_TYPES[0].id,
      to: today.format('YYYY-MM-DD'),
      from: today.subtract(1, 'month').format('YYYY-MM-DD'),
    }
  }

  isDateRequired = () => {
    return this.state.type !== 'reports/therapists_data';
  }

  handleSubmit = () => {
    let data = {};
    if (this.isDateRequired()) {
      data.from = this.state.from;
      data.to = this.state.to;
    }
    sendRequest({
      method: this.state.type,
      type: 'GET',
      data,
      success: (data) => {
        const csvData = new Blob([data], { type: 'text/csv;charset=utf-8;' });
        const fileName = `${this.state.type.replace('/', '_')}.csv`;
        saveAs(csvData, fileName);
      },
      error: (data) => {
      }
    });
  }

  renderTypeSelect = () => {
    return (
      <div className='inputContainer'>
        <label>Report type</label>
        <div className='inputElem'>
          <SelectInput
            object={this.state.type}
            onChange={(k, val) => this.setState({type: val})}
            properties={{ options: REPORT_TYPES }}
          />
        </div>
      </div>
    )
  }

  renderDateIntervalInput = () => {
    if (!this.isDateRequired()) {
      return null;
    }
    return (
      <div className='doubleInput'>
        <div className='inputContainer'>
          <label>From</label>
          <div className='inputElem'>
            <DateInput
              object={this.state.from}
              onChange={(k, val) => this.setState({from: val})}
              properties={{ type: 'date' }}
            />
          </div>
        </div>
        <div className='inputContainer'>
          <label>To</label>
          <div className='inputElem'>
            <DateInput
              object={this.state.to}
              onChange={(k, val) => this.setState({to: val})}
              properties={{ type: 'date' }}
            />
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    return (
      <div className='reportsView'>

        <div className='card'>
          <div className='inputsBlock'>
            {this.renderTypeSelect()}
            {this.renderDateIntervalInput()}
          </div>
          <div className='buttonContainer'>
            <button onClick={this.handleSubmit}>Generate</button>
          </div>
        </div>

      </div>
    )
  }
}
