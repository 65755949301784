import React from 'react';
import classnames from 'classnames';
import update from 'immutability-helper';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import appConfig from '../config/';
import { sendRequest, triggerEvent } from '../helpers/global.js';

import FlexibleInput from './FlexibleInput';
import { stateToHtml } from './input/WysiwygInput';

import '../sass/components/ObjectEditView.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
  }
};

class ObjectEditView extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  paramId = () => {
    const id = this.props.match.params.id;
    if (this.state.config.rootRequest) return this.state.object.id || '';
    return id === 'create' ? '' : id;
  }

  getDefaultState = (props) => {
    const pageConfig = appConfig[props.configKey];
    const properties = {...pageConfig.properties};
    const config = {...pageConfig.config};
    let object = {};
    Object.keys(properties).forEach(key => {
      if (props.object && props.object[key]) {
        object[key] = props.object[key];
      } else {
        const def = properties[key].default;
        if (Array.isArray(def)) {
          object[key] = def.slice();
        } else if (typeof def === "object" && def !== null) {
          object[key] = Object.assign({}, def);
        } else if (properties[key].type === "wysiwyg") {
          if (isNaN(props.match.params.id)) {
            object[key] = def;
          }
        } else {
          object[key] = def;
        }
      }
    });
    let state = {
      object: object,
      properties: properties,
      config: config,
      errors: {},
    }
    return state;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.configKey) {
      if (this.props.location !== prevProps.location) {
        this.setState(this.getDefaultState(this.props), () => {
          this.requestInitialData();
        });
      }
    }
  }

  updateStateWithData = (data) => {
    const properties = this.state.properties;
    let object = {...this.state.object, ...data};
    Object.keys(properties).forEach(key => {
      if (object[key] === undefined || object[key] === null) {
        object[key] = properties[key].default || null;
      }
    });
    this.setState({ object, properties });
  }

  componentDidMount = () => {
    this.requestInitialData();
  }

  requestInitialData = () => {
    if (!this.paramId() && this.state.object.rootRequest) {
      return;
    }
    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: "GET",
      success: (data) => {
        this.updateStateWithData(data);
      },
      error: (data) => {
      }
    });
  }

  handleSubmit = () => {
    let formData = new FormData();
    const errors = {}
    Object.keys(this.state.object).forEach(key => {
      const value = this.state.object[key];
      const properties = this.state.properties[key];
      const hidden = properties?.showCondition && !properties.showCondition(this.state.object);

      if ((properties?.requiredCondition?.(this.state.object) || properties?.required) && !value && !hidden) {
        errors[key] = 'Is required'
      }
      if (!properties) {
      } else if (value === null || value === undefined) {
        if (properties.type === 'select') {
          formData.append(key, '');
        }
      } else if (['image', 'file'].includes(properties.type)) {
        if (typeof value !== 'string') {
          formData.append(key, value);
        }
      } else if (properties.type === 'wysiwyg') {
        formData.append(key, stateToHtml(value));
      } else if (['multi-select', 'todo-list', 'checklist', 'session-settings'].includes(properties.type)) {
        formData.append(key, JSON.stringify(value));
      } else if (properties.type === 'items-group') {
        value.forEach(group => {
          group.items.forEach(checklist => {
            checklist.description = stateToHtml(checklist.description);
          });
        });
        formData.append(key, JSON.stringify(value));
      } else if (properties.type === 'survey-questions') {
        formData.append(key, JSON.stringify(value.filter(i => i.question_type)));
      } else if (properties.type === 'duration') {
        Object.keys(value).forEach(duration_key => formData.append(duration_key, value[duration_key]))
      } else {
        formData.append(key, value);
      }
    });
    if (Object.keys(errors).length) {
      this.setState({errors: errors}, this.scrollIntoError);
      return
    }
    sendRequest({
      method: this.state.config.method + this.paramId(),
      type: this.paramId() ? "PUT" : "POST",
      formData,
      success: (data) => {
        if (data.id) {
          this.updateStateWithData(data);
          this.props.history.push(`/${this.props.configKey}`);
          triggerEvent('showSnackbar', [{text: `${this.state.config.objectName} saved`}]);
        } else if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      },
      error: (data) => {
        if (data.errors) {
          this.setState({errors: data.errors}, this.scrollIntoError);
        }
      }
    });
  }

  handleValueChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
      errors: {
        [key]: {$set: null},
      },
    }));
  }

  scrollIntoError = () => {
    const keys = Object.keys(this.state.errors);
    if (keys[0]) {
      const elem = document.querySelector(`#input-${keys[0]}`);
      if (elem) {
        elem.scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }
  }

  renderInput = (key) => {
    const inputProperties = this.state.properties[key];
    if (!inputProperties) {
      return null;
    }
    if (!inputProperties.type || ["none", "avatar"].includes(inputProperties.type)) {
      return null;
    }
    if (inputProperties.showCondition && !inputProperties.showCondition(this.state.object)) {
      return null;
    }
    if (inputProperties.type === 'documents-list' && !this.state.object.id) {
      return null;
    }
    let groupHidden = inputProperties.preset && !this.state.object.id;
    let inputDisabled = inputProperties.preset;
    if (inputProperties.createOnly) {
      inputDisabled = true;
    }
    if (inputProperties.modifyCondition && !inputProperties.modifyCondition(this.state.object)) {
      inputDisabled = true;
    }
    if (this.state.config.readOnly || this.state.object.deleted || !this.props.user.admin_write) {
      inputDisabled = true;
    }
    return (
      <div
        key={key}
        id={`input-${key}`}
        className={classnames({
          'inputContainer': true,
          'hidden': groupHidden,
        })}
      >
        {inputProperties.title ?
          <label>{inputProperties.title}</label>
        : null}
        <div className='inputElem'>
          <FlexibleInput
            object={this.state.object[key]}
            parentObject={this.state.object}
            onChange={this.handleValueChange}
            disabled={inputDisabled}
            objectKey={key}
            properties={inputProperties}
            errors={this.state.errors[key]}
          />
          {this.state.errors[key] ?
            <div className='inputError'>{this.state.errors[key]}</div>
          : null}
        </div>
      </div>
    );
  }

  render = () => {
    const { modifyCondition, readOnly, bottomControls = [], editView } = this.state.config;
    let actionButton = <button onClick={this.handleSubmit}>Save</button>;
    if (readOnly || this.state.object.deleted || !this.props.user.admin_write) {
      actionButton = null;
    }
    if (modifyCondition && !modifyCondition(this.state.object)) {
      actionButton = null;
    }

    return (
      <div className='objectEditView'>

        {editView ? <>
          {React.createElement(editView, {
            config: this.state.config,
            properties: this.state.properties,
            item: this.state.object,
            onChange: this.handleValueChange,
            onError: errors => this.setState({errors}, this.scrollIntoError),
            renderInput: this.renderInput,
          })}
          <div className='buttonContainer left'>
            {actionButton}
          </div>
          </> :
          <div className='card'>
            <div className='inputsBlock'>
              {Object.keys(this.state.properties).map(this.renderInput)}
            </div>
            <div className='buttonContainer'>
              {actionButton}
            </div>
          </div>
        }

        {bottomControls.map((control, i) =>
          React.createElement(control, {
            key: i,
            item: this.state.object,
            onChange: object => this.setState({object}),
          })
        )}

      </div>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(ObjectEditView))
