import React from 'react';
import { connect } from 'react-redux';

import '../../sass/components/input/ChangesInput.scss';

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ChangesInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidMount = () => {
  }

  renderValue = (value) => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    if (value && value.constructor.name === 'Array') {
      return (
        value.map((val, index) => <div key={index}>{val}</div>)
      )
    }
    return value || (this.props.isMobile ? '' : '-');
  }

  renderDesktop = () => {
    const object = this.props.object || [];
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th width="155px">Field</th>
            <th width="auto">Changed from</th>
            <th width="auto">Changed to</th>
          </tr>
        </thead>
        <tbody>
          {object.map((item, index) =>
            <tr key={index}>
              <td>{item.field}</td>
              <td>{this.renderValue(item.from)}</td>
              <td>{this.renderValue(item.to)}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  renderMobile = () => {
    const object = this.props.object || [];
    return object.map((item, index) =>
      <table key={index} className="table table-hover">
        <tbody>
          <tr>
            <td width="110px">Field</td>
            <td>{item.field}</td>
          </tr>
          <tr>
            <td width="110px">Changed from</td>
            <td>{this.renderValue(item.from)}</td>
          </tr>
          <tr>
            <td width="110px">Changed to</td>
            <td>{this.renderValue(item.to)}</td>
          </tr>
        </tbody>
      </table>
    )
  }

  render = () => {
    return (
      <div className='changesInput'>
        {this.props.isMobile ? this.renderMobile() : this.renderDesktop()}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(ChangesInput)
