export default {
  section: "Data",
  config: {
    method: "corporate_sites/",
    title: "Corporate sites",
    objectName: "Site",
    deletionMsg: "If removed, the employees Site tag will be empty. This action cannot be undone.",
    search: {
      corporate_id: {
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      name: {
        default: "",
        type: "text",
        title: "Name",
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Updated at",
      preset: true,
    },
    corporate_id: {
      type: "select",
      title: "Corporate",
      clearable: true,
      async: true,
      request: "users/search?role=corporate",
      width: "auto",
    },
    name: {
      default: "",
      type: "text",
      title: "Name",
      width: "auto",
    },
  }
};
