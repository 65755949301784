export default {
  rootSection: true,
  config: {
    method: "rate_settings/",
    title: "Rate settings",
    objectName: "Rate setting",
    disableAdd: true,
    disableDelete: true,
    editView: require('../components/editControls/EditViewWithLogs.js').default,
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Updated at",
      preset: true,
    },
    setting_type: {
      default: "",
      type: "select",
      title: "Setting",
      options: [
        { id: "conversion_fee", name: "Conversion fee" },
        { id: "usd_rate", name: "SGD to USD" },
        { id: "php_rate", name: "SGD to PHP" },
        { id: "myr_rate", name: "SGD to MYR" },
      ],
      modifyCondition: object => false,
      width: "auto",
    },
    value: {
      default: "",
      type: "text",
      title: "Value",
      width: "auto",
    },
  }
};
