import React from 'react';
import classnames from 'classnames';
import update from 'immutability-helper';
import CSSTransition from 'react-transition-group/CSSTransition';

import TagsSelect from '../input/TagsSelect.js';
import FlexibleInput from '../FlexibleInput.js';

import { eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/BulkEditPopup.scss';

class BulkEditPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      confirmationView: false,
      show: false,
      title: null,
      callback: null,
      config: null,
      entity: null,
      items: [],
      object: this.getDefaultObject(props.config),
      activeParams: [],
      searchKeys: {},
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showBulkEditPopup', this.showBulkEditPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showBulkEditPopup', this.showBulkEditPopup);
    }
  }

  getDefaultObject = (config) => {
    const bulkKeys = config && Object.keys(config).filter(key =>
      !config[key].showCondition || config[key].showCondition()
    );
    const defaultObject = bulkKeys && bulkKeys.reduce((prev, current) => ({ ...prev, [current]: config[current].default }), {})
    return defaultObject || {};
  }

  showBulkEditPopup = (e) => {
    const { title, callback, config, items, entity } = e.detail[0];
    this.setState({
      hidden: false,
      show: true,
      title,
      entity,
      callback,
      config,
      items,
      object: this.getDefaultObject(config)
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({ show: false });
    }
  }

  onActiveParamsChange = (value) => {
    if (this.state.activeParams.includes(value)) {
      const defaultObject = this.getDefaultObject(this.props.config);
      this.setState({
        activeParams: this.state.activeParams.filter(item => item !== value),
        object: {
          ...this.state.object,
          [value]: defaultObject[value]
        },
      });
    } else {
      this.setState(prevState => ({
        activeParams: [...prevState.activeParams, value]
      }));
    }
  }

  onConfirm = () => {
    if (this.state.confirmationView) {
      const bulkParams = Object.fromEntries(
        Object.entries(this.state.object).filter(([key]) => this.state.activeParams.includes(key))
      );
      if (this.state.callback(bulkParams)) {
        this.setState({ show: false });
      }
      this.setState({
        show: false,
        confirmationView: false,
        object: this.getDefaultObject(this.props.config),
        activeParams: [],
      });
    } else {
      this.setState({ confirmationView: true });
    }
  }

  onBack = () => {
    if (this.state.confirmationView) {
      this.setState({ confirmationView: false });
    } else {
      this.setState({ show: false });
    }
  }

  handleChange = (key, value) => {
    this.setState(update(this.state, {
      object: {
        [key]: {$set: value},
      },
    }));
  }

  handleSearchKeyChange = (key, value) => {
    this.setState(prevState => ({
      searchKeys: {
        ...prevState.searchKeys,
        [key]: value
      }
    }));
  }

  renderContentContainer = () => {
    const { config } = this.props.global ? this.state : this.props;
    const bulkKeys = config && Object.keys(config).filter(key =>
      !config[key].showCondition || config[key].showCondition()
    );
    return (
      <div className='contentContainer'>
        {bulkKeys && bulkKeys.map(key => this.renderBulkField(key))}
      </div>
    );
  }

  renderBulkField = (key) => {
    const { object, activeParams } = this.state;
    const { config } = this.props.global ? this.state : this.props;
    const properties = config[key];
    if (properties.multipleSearch) {
      properties['searchKey'] = this.state.searchKeys[key];
    }
    return (
      <div
        key={key}
        className={`bulkField ${config[key].type}`}
      >
        <label>{config[key].title}</label>
        {properties.multipleSearch
          ? <TagsSelect
              options={properties.searchKeys}
              onChange={(val) => this.handleSearchKeyChange(key, val)}
              value={this.state.searchKeys[key] || 'name'}
              disabled={!activeParams.includes(key)}
            />
          : null}
        <div className='bulkFieldContent'>
          <div className='inputElem'>
            <FlexibleInput
              object={object[key]}
              onChange={this.handleChange}
              objectKey={key}
              properties={config[key]}
              disabled={!activeParams.includes(key)}
            />
          </div>
          <div className='action'>
            {activeParams.includes(key)
              ? <span
                  className='cancel'
                  onClick={() => this.onActiveParamsChange(key)}
                >Cancel</span>
              : <span
                  className='material-icons'
                  onClick={() => this.onActiveParamsChange(key)}
                >create</span>}
          </div>
        </div>
      </div>
    );
  }

  render = () => {
    const { show, title, entity, items } = this.props.global ? this.state : this.props;
    return (
      <div
        className={classnames({
          'bulkEditPopup': true,
          'hidden': this.state.hidden,
          'confirmationView': this.state.confirmationView,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              {this.state.confirmationView
                ? null
                : <div className='popupTitle'>{title}</div>}
              <div className='closeButton' onClick={() => this.setState({ show: false })}/>
              {this.state.confirmationView
                ? null
                : this.renderContentContainer()}
              <div
                className={classnames({
                  'hint': true,
                  'confirmationHint': this.state.confirmationView,
                })}
              >
                {this.state.confirmationView
                  ? <>
                      <p>Are you sure you want to bulk edit the <b>{items.length} items</b> selected on <b>{entity}</b> page?</p>
                      <p>This action cannot be undone.</p>
                    </>
                  : <p>All edits above will be applied to the selected items.</p>}
              </div>
              <div className='popupControls'>
                <div
                  className={classnames({
                    'popupControl': true,
                    'confirm': true,
                    'uppercase': !this.state.confirmationView,
                  })}
                  onClick={() => this.onConfirm()}
                >Confirm Bulk Edit</div>
                <div
                  onClick={() => this.onBack()}
                  className='popupControl back'
                >{this.state.confirmationView ? 'Back to Editing' : 'Back'}</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default BulkEditPopup;
