import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './config';

import Storage from './helpers/Storage.js';
import App from './App';

import './sass/base.scss';
import './sass/animations.scss';

require('./css/index.css');
require('./css/material-icons.css');
require('./css/react-select.css');
require('react-datetime/css/react-datetime.css');
require('react-draft-wysiwyg/dist/react-draft-wysiwyg.css');

const init = async () => {
  try {
    await Promise.all([
      Storage.loadData('user'),
    ])
  } catch (ex) {}
}

init().then(() => {
  ReactDOM.render(
    <Provider store={Storage.getStore()}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>,
    document.getElementById('content')
  )
});
