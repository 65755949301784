import React from 'react';

import { sendRequest, fileToImageData } from '../../helpers/global';

import '../../sass/components/input/PhotosInput.scss';

export const uploadImage = (url, file, successHandler, processHandler, type = 'POST') =>
  fileToImageData(file, 1024, (data) => {
    fetch(data)
      .then(res => res.blob())
      .then(blob => {
        const image = new File([blob], 'Image.png', { type: 'image/png' });
        let formData = new FormData();
        formData.append('image', image);
        sendRequest({
          method: url,
          type,
          formData,
          success: successHandler,
          error: (data) => {
          }
        });
      });
    processHandler();
  });

export default class PhotosInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this.input = null;
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  handleFiles = (files) => {
    const { object, properties, parentObject } = this.props;
    const file = files.shift();
    if (!file) {
      return;
    }
    uploadImage(properties.createRequest.replace(':id', parentObject.id), file,
      (data) => {
        this.handleChange([...object, data]);
        this.handleFiles(files);
      }, () => this.input.value = null);
  }

  removeItem = (item) => {
    const { object, properties, parentObject } = this.props;
    sendRequest({
      method: properties.deleteRequest
        .replace(':id', parentObject.id)
        .replace(':item_id', item.id),
      type: 'DELETE',
      success: (data) => {
        this.handleChange(object.filter(i => i.id !== item.id));
      },
      error: (data) => {
      }
    });
  }

  renderItem = (item) => {
    return (
      <div
        key={item.id}
        className='photoItem'
      >
        <div
          className='photoContent'
          style={{backgroundImage: `url(${item.image_url})`}}
        >
          <div
            className='removeButton'
            onClick={() => this.removeItem(item)}
          >Remove</div>
        </div>
      </div>
    )
  }

  renderAddItem = () => {
    return (
      <div className='photoItem'>
        <div
          className='photoContent add'
          onClick={() => this.input.click()}
        >
          <span className='material-icons'>add</span>
          <input
            type='file'
            accept='image/x-png,image/gif,image/jpeg'
            multiple
            ref={input => this.input = input}
            onChange={e => this.handleFiles([...e.target.files])}
          />
        </div>
      </div>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='photosInput'>
        {object.map(this.renderItem)}
        {this.renderAddItem()}
      </div>
    )
  }

}
