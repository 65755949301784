export default {
  section: "Data",
  config: {
    method: "banners/",
    title: "Banners",
    objectName: "Banner",
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    name: {
      default: "",
      type: "text",
      title: "Promotion name",
      width: "auto",
    },
    location: {
      default: "booking_flow_select_therapist",
      type: "select",
      title: "Location",
      options: [
        { id: "booking_flow_select_therapist", name: "Booking Flow Select Therapist page" },
      ],
      required: true,
    },
    title: {
      default: "",
      type: "wysiwyg",
      title: "Title",
    },
    description: {
      default: "",
      type: "wysiwyg",
      title: "Description",
    },
    image: {
      default: null,
      type: "image",
      title: "Image",
    },
    ends_at_unix: {
      default: "",
      type: "datetime",
      title: "Ends at",
    },
  }
};
