import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { sendRequest, triggerEvent } from '../../helpers/global.js';
import { withRouter } from 'react-router-dom';

import '../../sass/components/input/ClientActivity.scss';

const STATUSES = [
  { id: "pending", name: "Safe Space Staff is arranging the webinar for you" },
  { id: "new_request", name: "Safe Space Staff is arranging the webinar for you" },
  { id: "completed", name: "Your webinar is completed" },
  { id: "pending_client_review", name: "Pending Your Review: Safe Space Staff has updated the webinar details" },
  { id: "confirmed", name: "Your webinar is scheduled successfully!" },
  { id: "cancelled", name: "Your webinar is cancelled." }
];

const mapStoreToProps = (store) => {
  return {
    isMobile: store.setup.is_mobile,
  }
};

class ClientActivity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  sendRespondToClient = (webinarData) => {
    const webinarBookingId = this.props.match.params.id;
    triggerEvent('showResponseToClientPopup', [{
      bookingId: webinarBookingId,
      webinarData: {
        preferences: webinarData.preferences,
        created_at: webinarData.created_at
      },
      callback: (result, bookingData) => {
        if (result) {
          sendRequest({
            method: `webinar_bookings/${webinarBookingId}`,
            type: 'PUT',
            data: bookingData,
            success: (data) => {
              Object.keys(bookingData).filter(key => this.props.onChange(key, data[key]));
              sendRequest({
                method: `webinar_booking_requests/${webinarData.id}`,
                type: 'POST',
                data: {
                  status: bookingData.status,
                  webinar_booking_id: webinarBookingId,
                  webinar_booking_request_id: data.id
                },
                success: (requestData) => {
                  webinarData['webinar_booking_request_id'] = data.id;
                  this.addInfoItem(requestData);
                },
                error: (requestData) => {
                }
              });
            },
            error: (data) => {
            }
          });
        }
      }
    }]);
  }

  addInfoItem = (data) => {
    const object = (this.props.object || []);
    object.push(data);
    this.props.onChange(this.props.objectKey, object);
  }

  renderDatetime = (content) => {
    return moment.unix(content).format('DD.MM.YYYY HH:mm');
  }

  renderStatusName = (status) => {
    const item = STATUSES.find(i => i.id === status);
    return item?.name || '';
  }

  renderInfoItem = (item) => {
    if (this.props.isMobile) {
      return (
        <table key={item.id} className='card'>
          <tbody>
            <tr>
              <td>Response Date /Time</td>
              <td>{this.renderDatetime(item.created_at)}</td>
            </tr>
            <tr>
              <td>Date/Time Preferences</td>
              <td>{item.preferences}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>{this.renderStatusName(item.status)}</td>
            </tr>
            {item.status === 'new_request'
              ? <tr>
                  <td colSpan={2}>{this.renderSendButton(item)}</td>
                </tr>
              : null}
          </tbody>
        </table>
      )
    } else {
      return (
        <tr key={item.id}>
          <td>{this.renderDatetime(item.created_at)}</td>
          <td>{item.preferences}</td>
          <td>{this.renderStatusName(item.status)}</td>
          <td>{item.status === 'new_request' ? this.renderSendButton(item) : null}</td>
        </tr>
      )
    }
  }

  renderSendButton = (item) => {
    return (
      <button
        onClick={() => this.sendRespondToClient(item)}
        disabled={item.webinar_booking_request_id}
      >
        Respond to client
      </button>
    )
  }

  render = () => {
    const object = this.props.object || [];
    return (
      <div className='clientActivity'>
        {this.props.isMobile
          ? object.map((item, i) => this.renderInfoItem(item))
          : <table>
              <thead>
                <tr>
                  <th>Response Date /Time</th>
                  <th>Date/Time Preferences</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {object.map((item, i) => this.renderInfoItem(item, i + 1 === object.length))}
              </tbody>
            </table>}
      </div>
    )
  }

}

export default connect(mapStoreToProps)(withRouter(ClientActivity));
