import React from 'react';

import TextInput from './TextInput.js';
import SelectInput from './SelectInput.js';

import '../../sass/components/input/DurationInput.scss';

const DURATION_TYPES = [
  { id: 'minutes', name: 'Minutes' },
  { id: 'hours', name: 'Hours' },
];

export default class DurationInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = () => {
  }

  handleChange = (key, value) => {
    const object = this.props.object;
    object[key] = value;
    this.props.onChange(this.props.objectKey, object);
  }

  render = () => {
    const object = this.props.object;
    return (
      <div className='durationInput'>
        <TextInput
          object={object?.duration || 0}
          onChange={(k, value) => this.handleChange('duration', value)}
          properties={{
            type: 'number'
          }}
        />
        <SelectInput
          object={object?.duration_type}
          onChange={(k, value) => this.handleChange('duration_type', value)}
          properties={{
            options: DURATION_TYPES
          }}
        />
      </div>
    )
  }
}
