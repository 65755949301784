import React from 'react';
import classnames from 'classnames';

import { sendRequest } from '../../helpers/global';

import '../../sass/components/input/TextInput.scss';

const validateLowerCase = (password) => password.match(/(?=.*[a-z])/);
const validateUpperCase = (password) => password.match(/(?=.*[A-Z])/);
const validateNumber = (password) => password.match(/(?=.*[0-9])/);
const validateSpecial = (password) => password.match(/(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/);
const validateLength = (password) => password.length >= 10;

export default class TextInput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      showPassword: false,
    };
    this.input = null;
  }

  componentDidMount = () => {
  }

  getWordCount = (value) => {
    return (value || '').replace(/\n/g, ' ').split(' ').filter(i => i.trim()).length;
  }

  handleChange = (e) => {
    let value = e.target.value;
    const properties = this.props.properties;
    if (properties.wordLimit) {
      const wordCount = this.getWordCount(value);
      if (wordCount > properties.wordLimit) {
        return;
      }
    }
    if (properties.type === 'integer') {
      value = value.replace(/\D/g, '');
    }
    if (this.props.onChange) {
      this.props.onChange(this.props.objectKey, value);
    }
    if (this.props.properties.validation) {
      this.validateInput(value)
    }
  }

  validateInput = (val) => {
    const key = this.props.objectKey;

    let formData = new FormData();
    formData.append('id', this.props.parentObject && this.props.parentObject.id);
    formData.append('field', key);
    formData.append(key, val);

    sendRequest({
      type: 'POST',
      method: this.props.properties.validation,
      data: formData,
      noLoad: true,
      success: (data) => {
        if (this.props.onValidation) {
          this.props.onValidation(data, key);
        }
      },
      error: (data) => {
      }
    });
  }

  renderTextInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    const type =
      properties.type === 'integer' ? 'text' :
      properties.type === 'password' && this.state.showPassword ? 'text' :
      properties.type;
    return (
      <input
        type={type}
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        placeholder={this.props.placeholder || properties.placeholder}
        autoComplete={this.props.objectKey}
        onFocus={() => this.setState({focused: true})}
        onBlur={() => this.setState({focused: false})}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  renderTextareaInput = () => {
    const properties = this.props.properties;
    let value = this.props.object;
    if ([null, undefined].indexOf(value) > -1 && this.props.onChange) {
      value = '';
    }
    return (
      <textarea
        value={value}
        onChange={this.handleChange}
        readOnly={this.props.disabled}
        placeholder={this.props.placeholder || properties.placeholder}
        onFocus={() => this.setState({focused: true})}
        onBlur={() => this.setState({focused: false})}
        ref={input => {
          this.input = input;
          if (this.props.inputRef) {
            this.props.inputRef(this.input);
          }
        }}
      />
    )
  }

  renderPasswordHint = () => {
    if (!this.state.focused) {
      return null;
    }
    const password = this.props.object || '';
    return (
      <div className='passwordHint'>
        <div className='requirementsTitle'>Password Requirements:</div>
        <div className={classnames('requirement', {
          'checked': validateLowerCase(password),
        })}>Lower case letters (a-z)</div>
        <div className={classnames('requirement', {
          'checked': validateUpperCase(password),
        })}>Upper case letters (A-Z)</div>
        <div className={classnames('requirement', {
          'checked': validateNumber(password),
        })}>Number (0-9)</div>
        <div className={classnames('requirement', {
          'checked': validateSpecial(password),
        })}>Special character ({'!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~'})</div>
        <div className={classnames('requirement', {
          'checked': validateLength(password),
        })}>At least 10 characters</div>
      </div>
    )
  }

  renderPasswordToggle = () => {
    const { showPassword } = this.state;
    return (
      <div
        className={classnames({
          'passwordToggle': true,
          'active': showPassword,
        })}
        onClick={() => this.setState({showPassword: !showPassword})}
      />
    )
  }

  renderWordLimit = () => {
    const properties = this.props.properties;
    const wordCount = this.getWordCount(this.props.object);
    return (
      <div className='limitLabel'>
        {wordCount} / {properties.wordLimit} Words
      </div>
    )
  }

  render = () => {
    const properties = this.props.properties;
    return (
      <div
        className={classnames({
          'textInput': true,
          'simplified': properties.simplified,
        })}
      >
        {['text', 'number', 'integer', 'password', 'amount'].indexOf(properties.type) > -1 ? this.renderTextInput() : null}
        {properties.type === 'textarea' ? this.renderTextareaInput() : null}
        {properties.type === 'password' ? this.renderPasswordHint() : null}
        {properties.type === 'password' ? this.renderPasswordToggle() : null}
        {properties.wordLimit ? this.renderWordLimit() : null}
      </div>
    )
  }
}
