import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

import { triggerEvent, sendRequest } from '../../helpers/global';

import '../../sass/components/input/SelectInput.scss';

const EXPAND_THRESHOLD = 20;

class SelectInput extends React.Component {
  constructor(props) {
    super();
    this.state = {
      options: props.properties.options || [],
      expanded: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const properties = this.props.properties;
    if (prevProps.properties.options !== this.props.properties.options) {
      this.setState({options: this.props.properties.options});
    } else if (prevProps.object !== this.props.object) {
      if (properties.async && !prevProps.object) {
        this.loadInitialOptions();
      }
    }
  }

  handleChange = (value) => {
    this.props.onChange(this.props.objectKey, value);
  }

  componentDidMount = () => {
    this.loadInitialOptions();
  }

  loadInitialOptions = () => {
    const properties = this.props.properties;
    const isMulti = properties.type === 'multi-select';
    if (properties.request) {
      if (properties.async && !this.props.object) {
        return;
      }
      triggerEvent('addLoad');
      sendRequest({
        type: 'GET',
        method: properties.requestAll
          ? properties.requestAll.replace(':id', this.props.parentObject.id)
          : properties.request,
        data: properties.async && !properties.requestAll ? {
          ids: isMulti ? this.props.object : [this.props.object],
        } : null,
        success: (data) => {
          triggerEvent('removeLoad');
          this.setState({options: data});
        },
        error: (error) => {
          triggerEvent('removeLoad');
        }
      });
    }
  }

  loadOptions = (inputValue, callback) => {
    const properties = this.props.properties;
    const searchKey = properties.searchKey || 'name';
    const isMulti = properties.type === 'multi-select';
    const arrayObject = this.props.object || [];
    const collapsed = properties.requestAll && (this.props.object || []).length > EXPAND_THRESHOLD;
    if (properties.request) {
      sendRequest({
        type: 'GET',
        method: properties.request,
        data: {
          [searchKey]: inputValue
        },
        success: (data) => {
          let optionsCache = {};
          this.state.options.forEach(i => {
            optionsCache[i.id] = i[searchKey].trim();
          });
          data.forEach(i => {
            let value = i[searchKey].trim();
            if (properties.extraOptionKeys) {
              properties.extraOptionKeys.forEach(key => {
                value += ' – ' + i[key].trim();
              });
            }
            optionsCache[i.id] = value;
          });
          let options = Object.keys(optionsCache).map(key => ({
            id: key, [searchKey]: optionsCache[key]
          }));
          this.setState({options});
          callback(
            options
              .map(i => ({ value: i.id, label: i[searchKey] }))
              .filter(i => {
                if (!i.label.toLowerCase().includes(inputValue.toLowerCase())) {
                  return false;
                }
                if (isMulti) {
                  const shownValues = collapsed && !this.state.expanded ? arrayObject.slice(0, EXPAND_THRESHOLD) : arrayObject;
                  if (arrayObject.find(j => j == i.value) && !shownValues.find(j => j == i.value)) { // eslint-disable-line eqeqeq
                    return false;
                  }
                }
                return true;
              })
          );
        },
        error: (error) => {
          callback([]);
        }
      });
    } else {
      callback([]);
    }
  };

  getValue = (options) => {
    const object = this.props.object;
    const properties = this.props.properties;
    const isMulti = properties.type === 'multi-select';
    if (isMulti) {
      let values = (object || []);
      if (properties.requestAll && !this.state.expanded) {
        values = values.slice(0, EXPAND_THRESHOLD);
      }
      return values.map(v => options.find(i => i.value == v)).filter(v => !!v) // eslint-disable-line eqeqeq
    } else {
      return options.find(i => i.value == object); // eslint-disable-line eqeqeq
    }
  }

  render = () => {
    const arrayObject = this.props.object || [];
    const properties = this.props.properties;
    const isMulti = properties.type === 'multi-select';
    const collapsed = isMulti && properties.requestAll && (this.props.object || []).length > EXPAND_THRESHOLD;
    let options = this.state.options.map(i => ({
      value: i.id,
      label: i[properties.searchKey || 'name']
    }));
    if (isMulti) {
      const shownValues = collapsed && !this.state.expanded ? arrayObject.slice(0, EXPAND_THRESHOLD) : arrayObject;
      options = options.filter(i =>
        !(arrayObject.find(j => j == i.value)) || shownValues.find(j => j == i.value) // eslint-disable-line eqeqeq
      )
    }
    const selectProps = {
      value: this.props.object === undefined ? undefined : this.getValue(options) || null,
      onChange: (e, data) => {
        if (isMulti) {
          if (data.action === 'select-option') {
            this.handleChange([...arrayObject, data.option.value]);
          } else if (data.action === 'remove-value') {
            this.handleChange(arrayObject.filter(i => i != data.removedValue.value)); // eslint-disable-line eqeqeq
          }
        } else {
          this.handleChange(e ? e.value : null);
        }
      },
      onFocus: () => this.setState({focus: true}),
      onBlur: () => this.setState({focus: false}),
      isMulti: isMulti,
      isDisabled: this.props.disabled,
      className: 'reactSelect',
      classNamePrefix: 'reactSelect',
      isClearable: properties.clearable,
      placeholder: properties.placeholder || 'Select...',
      noOptionsMessage: inputValue => properties.async ? 'Search...' : 'No options',
    }
    return (
      <div
        className={classnames({
          'selectInput': true,
          'readOnly': this.props.disabled,
        })}
      >
        {properties.async
          ? <AsyncSelect
              cacheOptions={!isMulti}
              defaultOptions={options}
              loadOptions={this.loadOptions}
              {...selectProps}
            />
          : <Select
            options={options}
            {...selectProps}
          />
        }
        {collapsed ?
          <div
            className='showAll'
            onClick={() => this.setState({expanded: !this.state.expanded})}
          >
            {this.state.expanded
              ? `Collapse to ${EXPAND_THRESHOLD}`
              : `Show ${(this.props.object || []).length - EXPAND_THRESHOLD} More`
            }
          </div>
        : null}
      </div>
    )
  }
}

export default SelectInput;
