import React from 'react';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';
import update from 'immutability-helper';
import moment from 'moment';

import DateInput from '../input/DateInput.js';
import SelectInput from '../input/SelectInput.js';
import TextInput from '../input/TextInput.js';

import { sendRequest, eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/ResponseToClientPopup.scss';

const STATUSES = [
  { id: "pending", name: "Safe Space Staff is arranging the webinar for you" },
  { id: "new_request", name: "Safe Space Staff is arranging the webinar for you" },
  { id: "completed", name: "Your webinar is completed" },
  { id: "pending_client_review", name: "Pending Your Review: Safe Space Staff has updated the webinar details" },
  { id: "confirmed", name: "Your webinar is scheduled successfully!" },
  { id: "cancelled", name: "Your webinar is cancelled." }
];

class ResponseToClientPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      callback: null,
      webinarData: {
        preferences: ''
      },
      errors: {},
      bookingData: {
        status: null,
        therapist_id: null,
        starts_at_unix: null,
        ends_at_unix: null,
        webinar_id: null
      }
    };
  }

  componentDidMount = () => {
    if (this.props.global) {
      eventOn('showResponseToClientPopup', this.showResponseToClientPopup);
    }
  }

  componentWillUnmount = () => {
    if (this.props.global) {
      eventOff('showResponseToClientPopup', this.showResponseToClientPopup);
    }
  }

  requestData = (bookingId) => {
    sendRequest({
      type: 'GET',
      method: `webinar_bookings/${bookingId}`,
      success: (data) => {
        this.setState(update(this.state, {
          bookingData: {
            'status': {$set: data.status},
            'therapist_id': {$set: data.therapist_id},
            'starts_at_unix': {$set: data.starts_at_unix},
            'ends_at_unix': {$set: data.ends_at_unix},
            'webinar_id': {$set: data.webinar_id},
          },
        }));
      }
    });
  }

  showResponseToClientPopup = (e) => {
    const { bookingId, callback, webinarData } = e.detail[0];
    this.requestData(bookingId);
    this.setState({
      hidden: false,
      show: true,
      callback,
      webinarData
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.setState({show: false});
    }
  }

  onSend = (value) => {
    const errors = {};
    let { bookingData } = this.state;
    if (!bookingData.starts_at_unix) {
      errors['starts_at_unix'] = 'Cannot be empty value';
    }
    if (!bookingData.ends_at_unix) {
      errors['ends_at_unix'] = 'Cannot be empty value';
    }
    if (Object.keys(errors).length) {
      this.setState({errors: errors});
      return;
    }
    if (this.props.global) {
      bookingData['status'] = 'pending_client_review';
      this.state.callback(value, bookingData);
      this.setState({show: false});
    } else {
      this.props.callback(value, bookingData);
    }
  }

  handleDataChange = (key, value) => {
    this.setState(update(this.state, {
      bookingData: {
        [key]: {$set: value},
      },
    }));
  }

  renderDate = (data) => {
    return moment.unix(data).format('DD.MM.YYYY HH:mm');
  }

  renderStatusName = (status) => {
    const item = STATUSES.find(i => i.id === status);
    return item?.name || '';
  }

  render = () => {
    const { show } = this.props.global ? this.state : this.props;
    const { bookingData, webinarData } = this.state;
    return (
      <div
        className={classnames({
          'responseToClientPopup': true,
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='popupTitle'>
                Respond to the Client’s Request at {this.renderDate(webinarData.created_at)}
              </div>
              <div className='closeButton' onClick={() => this.setState({show: false})}/>
              <div className='optionsList'>
                <div className='inputContainer'>
                  <label>Status</label>
                  <div className='inputElem'>
                    <TextInput
                      properties={{type: 'text'}}
                      object={this.renderStatusName(bookingData.status)}
                      disabled
                    />
                  </div>
                </div>
                <div className='inputContainer'>
                  <label>Date/Time Preferences</label>
                  <div className='inputElem'>
                    <TextInput
                      properties={{type: 'text'}}
                      object={webinarData.preferences}
                      disabled
                    />
                  </div>
                </div>
                <div className='inputContainer'>
                  <label>Your Arranged Time</label>
                  <div className='inputElem'>
                    <DateInput
                      properties={{type: 'datetime'}}
                      object={bookingData.starts_at_unix}
                      onChange={(k, val) => this.handleDataChange('starts_at_unix', val)}
                    />
                    {this.state.errors['starts_at_unix'] ?
                      <div className='inputError'>{this.state.errors['starts_at_unix']}</div>
                    : null}
                  </div>
                </div>
                <div className='inputContainer'>
                  <label>Ends At</label>
                  <div className='inputElem'>
                    <DateInput
                      object={bookingData.ends_at_unix}
                      properties={{type: 'datetime'}}
                      onChange={(k, val) => this.handleDataChange('ends_at_unix', val)}
                    />
                    {this.state.errors['ends_at_unix'] ?
                      <div className='inputError'>{this.state.errors['ends_at_unix']}</div>
                    : null}
                  </div>
                </div>
                <div className='inputContainer'>
                  <label>Topic</label>
                  <div className='inputElem'>
                    <SelectInput
                      object={bookingData.webinar_id}
                      properties={{
                        request: 'webinars/search',
                        searchKey: 'title',
                        async: true
                      }}
                      onChange={(k, val) => this.handleDataChange('webinar_id', val)}
                    />
                  </div>
                </div>
                <div className='inputContainer'>
                  <label>Therapist</label>
                  <div className='inputElem'>
                    <SelectInput
                      object={bookingData.therapist_id}
                      properties={{request: 'users/search?role=therapist'}}
                      onChange={(k, val) => this.handleDataChange('therapist_id', val)}
                    />
                  </div>
                </div>
              </div>
              <div className='popupControls'>
                <div
                  onClick={() => this.onSend(true)}
                  className='popupControl confirm'
                >Send to Client</div>
                <div
                  onClick={() => this.setState({show: false})}
                  className='popupControl cancel'
                >Back</div>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default ResponseToClientPopup;
