export default {
  section: "Data",
  config: {
    method: "webinar_bookings/",
    title: "Education bookings",
    objectName: "Event Booking",
    disableDelete: true,
    search: {
      corporate_id: {
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      webinar_id: {
        type: "select",
        title: "Topic",
        clearable: true,
        async: true,
        searchKey: "title",
        request: "webinars/search",
      },
      therapist_id: {
        default: null,
        type: "select",
        title: "Counsellor",
        clearable: true,
        async: true,
        request: "users/search?role=therapist",
      },
      starts_at: {
        default: "",
        type: "datetime",
        title: "Event Date (SGT)",
        required: true,
      },
      status: {
        type: "select",
        title: "Status",
        options: [
          { id: 'new_request', name: "🔴 New Request" },
          { id: 'pending_client_review', name: '🟠 Pending Client Review' },
          { id: 'pending', name: '🔴 Pending Safe Space' },
          { id: 'confirmed', name: '🟢 Confirmed' },
          { id: 'completed', name: '🟢 Completed' },
          { id: 'cancelled', name: '⚫️ Cancelled' },
        ],
      },
    },
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "60px",
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created time",
      preset: true,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified Time",
      preset: true,
    },
    webinar_datetime: {
      title: "Webinar date / time",
      default: "",
      type: "datetime",
      width: "auto",
      showCondition: () => false,
      sort: true,
      sortAs: "starts_at",
    },
    webinar_id: {
      default: null,
      type: "select",
      title: "Topic",
      clearable: true,
      async: true,
      searchKey: "title",
      request: "webinars/search",
    },
    webinar_object: {
      default: null,
      type: "page-link",
      page: "/webinars/:id",
      title: "Topic",
      labelKey: "title",
      width: "auto",
      showCondition: object => false
    },
    therapist_id: {
      default: null,
      type: "select",
      title: "Counsellor",
      clearable: true,
      async: true,
      request: "users/search?role=therapist",
    },
    therapist_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Counsellor",
      width: "auto",
      showCondition: object => false
    },
    corporate_id: {
      default: null,
      type: "select",
      title: "Corporate",
      clearable: true,
      async: true,
      request: "users/search?role=corporate",
    },
    corporate_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Corporate",
      width: "auto",
      showCondition: object => false
    },
    last_response_at: {
      title: "Last Response Date",
      default: "",
      type: "datetime",
      width: "auto",
      showCondition: () => false,
    },
    status: {
      type: "select",
      title: "Status",
      options: [
        { id: 'new_request', name: "🔴 New Request" },
        { id: 'pending_client_review', name: '🟠 Pending Client Review' },
        { id: 'pending', name: '🔴 Pending Safe Space' },
        { id: 'confirmed', name: '🟢 Confirmed' },
        { id: 'completed', name: '🟢 Completed' },
        { id: 'cancelled', name: '⚫️ Cancelled' },
      ],
      width: "auto",
    },
    price: {
      default: null,
      type: "text",
      title: "Price",
      required: true,
    },
    counsellor_price: {
      default: null,
      type: "text",
      title: "Counsellor price",
      required: true,
    },
    currency: {
      type: "select",
      title: "Currency",
      options: [
        { id: "sgd", name: "SGD" },
        { id: "myr", name: "MYR" },
        { id: "php", name: "PHP" },
        { id: "usd", name: "USD" },
      ],
    },
    starts_at_unix: {
      default: "",
      type: "datetime",
      title: "Starts at",
      required: true,
    },
    ends_at_unix: {
      default: "",
      type: "datetime",
      title: "Ends at",
      required: true,
    },
    link: {
      type: "text",
      title: "Link",
      requiredCondition: object => 'confirmed completed'.split(' ').includes(object.status)
    },
    payment_method: {
      title: "Payment method",
      type: "select",
      options: [
        { id: 'invoice', name: 'Invoice' },
        { id: 'promo_code', name: 'Promo code' },
        { id: 'credit_card', name: 'Credit card' },
      ],
    },
    invoice: {
      title: "Invoice",
      type: "text",
      showCondition: object => object.payment_method === 'invoice',
    },
    credit_card: {
      title: "Credit card",
      type: "text",
      showCondition: object => object.payment_method === 'credit_card',
    },
    promocode_object: {
      default: null,
      type: "page-link",
      page: "/promocodes/:id",
      title: "Promocode",
      labelKey: "code",
      showCondition: object => object.payment_method === 'promo_code',
    },
    location: {
      title: "Location",
      type: "multi-select",
      options: [
        { id: 'online', name: 'Online' },
        { id: 'offline', name: 'Offline' },
      ],
    },
    onsite_venue: {
      title: "Onsite Venue",
      type: "text",
    },
    expiry_date: {
      default: "",
      type: "date",
      title: "Expiry Date",
    },
    description: {
      default: "",
      type: "wysiwyg",
      title: "Description",
    },
    event_type: {
      title: "Type",
      type: "select",
      options: [
        { id: 'webinar', name: 'Webinar' },
        { id: 'workshop', name: 'Talk' },
        { id: 'online_workshop', name: 'Online workshop' },
      ],
    },
    client_activity: {
      default: null,
      type: "client-activity",
      title: "Client Activity",
      showCondition: object => object.client_activity?.length > 0,
    },
  }
};
