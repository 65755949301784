export default {
  section: "Data",
  config: {
    method: "appointments/",
    title: "Appointments",
    objectName: "Appointment",
    disableAdd: true,
    exportMethod: "appointments/csv_export",
    search: {
      status: {
        type: "select",
        title: "Status",
        options: [
          { id: 'pending', name: 'Scheduled' },
          { id: 'completed', name: 'Completed' },
          { id: 'cancelled', name: 'Cancelled' },
          { id: 'expired', name: 'Expired' },
          { id: 'late', name: 'Cancelled (Late)' },
        ],
        clearable: true,
      },
      patient_id: {
        type: "select",
        title: "Patient",
        async: true,
        clearable: true,
        request: "users/search?role=patient",
      },
      therapist_id: {
        type: "select",
        title: "Therapist",
        clearable: true,
        async: true,
        request: "users/search?role=therapist&inactive=true",
      },
      corporate_id: {
        type: "select",
        title: "Corporate",
        clearable: true,
        async: true,
        request: "users/search?role=corporate",
      },
      promocode_id: {
        type: "select",
        title: "Promocode",
        clearable: true,
        async: true,
        request: "promocodes/search",
        searchKey: "code",
      },
      created_at_min: {
        type: "datetime",
        title: "Date created min",
      },
      created_at_max: {
        type: "datetime",
        title: "Date created max",
      },
    },
    editView: require('../components/editControls/EditViewWithLogs.js').default,
    bottomControls: [
      require('../components/editControls/AppointmentControlsView.js').default,
    ],
  },
  properties: {
    id: {
      default: false,
      type: "text",
      title: "#",
      preset: true,
      width: "80px",
      sort: true,
    },
    created_at: {
      default: "",
      type: "datetime",
      title: "Created at",
      preset: true,
    },
    created_at_timestamp: {
      default: "",
      type: "datetime",
      title: "Created at",
      preset: true,
      width: "140px",
      showCondition: object => false,
    },
    updated_at: {
      default: "",
      type: "datetime",
      title: "Modified at",
      preset: true,
    },
    planned_at: {
      default: "",
      type: "datetime",
      title: "Scheduled Date Time",
      modifyCondition: object => object.status !== 'completed',
      width: "140px",
      sort: true,
    },
    patient_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Patient",
      width: "auto",
    },
    therapist_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Therapist",
      width: "auto",
    },
    corporate_object: {
      default: null,
      type: "page-link",
      page: "/users/:id",
      title: "Corporate",
      width: "auto",
    },
    promocode_object: {
      default: null,
      type: "page-link",
      page: "/promocodes/:id",
      title: "Promocode",
      labelKey: "code",
      width: "auto",
    },
    status: {
      type: "select",
      title: "Status",
      options: [
        { id: 'pending', name: 'Scheduled' },
        { id: 'completed', name: 'Completed' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'expired', name: 'Expired' },
        { id: 'late', name: 'Cancelled (Late)' },
      ],
      width: "90px",
    },
    cancel_reason: {
      type: "select",
      title: "Cancel reason",
      options: [
        { id: 'wrong_therapist', name: 'Wrong therapist' },
        { id: 'wrong_time', name: 'Wrong time' },
        { id: 'wrong_specialty', name: 'Wrong specialty' },
        { id: 'found_alternative', name: 'Found alternative' },
        { id: 'wrong_session_type', name: 'Wrong session type' },
        { id: 'wrong_counselling_type', name: 'Wrong counselling type' },
        { id: 'forgot_voucher', name: 'Forgot voucher' },
        { id: 'no_need', name: 'No counselling need anymore' },
      ],
      modifyCondition: object => false,
      showCondition: object => ['cancelled', 'late'].includes(object.status),
    },
    duration: {
      type: "select",
      title: "Duration",
      options: [
        { id: '60_minutes', name: '60 minutes' },
        { id: '90_minutes', name: '90 minutes' },
      ],
    },
    counselling_type: {
      type: "select",
      title: "Counselling type",
      options: [
        { id: 'video_call', name: 'Video call' },
        { id: 'face_to_face', name: 'Face to face' },
      ],
      width: "100px",
    },
    session_type: {
      type: "select",
      title: "Session type",
      options: [
        { id: 'individual', name: 'Individual' },
        { id: 'family', name: 'Family' },
        { id: 'couple', name: 'Couple' },
      ],
      width: "85px",
    },
    price: {
      type: "text",
      title: "Price",
      modifyCondition: object => false,
      width: "75px",
    },
    charged_price: {
      type: "text",
      title: "Charged price",
      modifyCondition: object => false,
    },
    discount: {
      type: "text",
      title: "Discount",
      modifyCondition: object => false,
    },
    specialization_ids: {
      type: "multi-select",
      title: "Specializations",
      request: "common_data/specializations",
      searchKey: "name",
      modifyCondition: object => false,
    },
    feelings: {
      type: "labels-list",
      title: "Feelings",
    },
    experience: {
      type: "labels-list",
      title: "Experience",
    },
    therapist_notes: {
      type: "text",
      title: "Notes to therapist",
      modifyCondition: object => false,
    },
    public_notes: {
      type: "textarea",
      title: "SOAP public notes",
      modifyCondition: object => false,
    },
  }
};
